import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { TranslateService } from '@ngx-translate/core';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-custom-tags',
  templateUrl: './custom-tags.component.html',
  styleUrls: ['./custom-tags.component.scss']
})
export class CustomTagsComponent implements OnInit {
  public subscriptions: any[] = <any>[];
  public errorMessage: string = '';
  public loading: boolean = false;
  /* pagination Info */
  maxSelect = 10;
  maxLengthSelect = 50;
  pageSize = 5;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];


  private _parentFormSubmitted = false;
  @ViewChild('tagInput') tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto') matAutocomplete: MatAutocomplete;
  tagCtrl = new FormControl();
  filteredTags: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);
  allTags: any[] = [];
  @Input() required: boolean = false;
  @Input() itemName: string = '';
  @Input() placeholder: string = '';
  @Input() value: any[] = [];
  @Input() dataType: string = 'tag';
  @Input()
  set parentFormSubmitted(parentFormSubmitted: boolean){
      this._parentFormSubmitted = parentFormSubmitted;
      if(parentFormSubmitted){
        if(this.form)
          this.form.onSubmit(undefined);
      }
  }
  get parentFormSubmitted(): boolean{
    return this._parentFormSubmitted;
  }
  @Output() onSelectReturn = new EventEmitter<any>();
  @ViewChild('dataForm') form: NgForm;
  constructor(private translate: TranslateService,
    private requestService: RequestService,
    private layoutUtilsService: LayoutUtilsService
  ) {
  }

  ngOnInit() {
    this.filterData('');
    this.subscriptions.push(
        this.tagCtrl.valueChanges.subscribe((data) => {
          if(data){
            if(!this.getTagItem(data)){
              this.filterData(data);
            }
          }else{
            this.filterData('');
          }
        })
    );
  }
  onSubmit() {
    // do nothing
  }
  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
  private getSelectedItem(val){
    for(let itm of this.value){
      if(val === itm.uid){
        return itm;
      }
    }
    return '';
  }
  private getSelectedItemByName(val){
    for(let itm of this.allTags){
      if(val === itm.name){
        return itm;
      }
    }
    return '';
  }
  private getTagItem(val){
    for(let itm of this.allTags){
      if(val === itm.uid){
        return itm;
      }
    }
    return '';
  }
  public setAttribute(val) {
      this.onSelectReturn.emit(val.value);
  }
  add(event: MatChipInputEvent): void {
    // Add fruit only when MatAutocomplete is not open
    // To make sure this does not conflict with OptionSelected Event
    // console.log('Try pass', event.value);
    if (!this.getSelectedItemByName(event.value)) {
      if(event.value.length <= this.maxLengthSelect){
        // console.log('pass', event.value);
        const input = event.input;
        const value = event.value;
        // Add our tag
        if ((value || '').trim()) {
          // this.value.push(value.trim());
          this.addData(value.trim());
        }

        // Reset the input value
        if (input) {
          input.value = '';
        }

        // this.tagCtrl.setValue('');
      }else{
        this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only add a tag of 50 characters'), this.translate.instant('Dismiss'));
      }
    }
  }

  remove(tag: any): void {
    const index = this.value.indexOf(tag);
    if (index >= 0) {
      this.value.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if(this.value.length <= this.maxSelect){
      if(!this.getSelectedItem(event.option.value)){
        if(!this.value){
          this.value = [];
        }
        this.value.push({uid: event.option.value, name: event.option.viewValue});
        this.onSelectReturn.emit(this.value);
      }
      this.tagInput.nativeElement.value = '';
      this.tagCtrl.setValue('');
    }else{
      this.layoutUtilsService.showNotification(this.translate.instant('Sorry you can only select 10 tags'), this.translate.instant('Dismiss'));
    }
  }
  public addData(term) {
    let newTag = { name: term };
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.saveData(this.dataType, newTag, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          let returnResult = data.results;
          let dataTag = {uid: returnResult.uid, name: returnResult.name};
          this.allTags = [dataTag];
          this.value.push(dataTag);
        }else {
          this.allTags = [];
        }
        this.filteredTags.next(this.allTags);
        this.loading = false;
      });
    }
  }
  public filterData(term) {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataList(this.dataType, {page: this.pageNumber , term: term, perpage: this.pageSize, orderbydir: this.orderDir, orderby: this.orderBy, fields: ['name']}, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        if (data) {
          this.allTags = data.results;
        }else {
          this.allTags = [];
        }
        this.filteredTags.next(this.allTags);
        this.loading = false;
      });
    }
  }
}

import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  NgZone,
  OnChanges,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';

import { ModalDialogComponent } from '../../../shared/components/custom-dialog/custom-dialog.component';
import * as moment from 'moment';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state(
        'in',
        style({
          transform: 'translateY(-100%)',
          display: 'none',
          opacity: 0,
        })
      ),
      state(
        'out',
        style({
          transform: 'translateY(0%)',
          display: 'initial',
          opacity: 1,
        })
      ),
      transition('in => out', animate('500ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out')),
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)', display: 'hidden' }),
      //   animate('3000ms ease-in', style({ transform: 'translateY(0%)' }))
      // ]),
      // transition('* => void', [
      //   animate('4000ms ease-in', style({ transform: 'translateY(-100%)' }))
      // ])
    ]),
  ],
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public orgType = environment.orgType;
  public lockSite = environment.lockSite;
  public hasListingDirectory = environment.hasListingDirectory;
  public permission: any[] = [environment.customKeys.roleAdmin];
  public viewPermission: any[] = [environment.customKeys.roleView];
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  public donateLink: string = undefined;
  public pushRightClass: string;
  public userType: string = 'default';
  isDnD: boolean = false;
  iframeDialog: MatDialogRef<any, any>;
  highlightDirectory: boolean = undefined;
  _isOpenGlobalChat: boolean = false;
  _isOpenAgenda: boolean = false;
  drawerState: string = 'in';
  // lastChatMessage: any = { message: '' };
  // videoChatInvite: boolean = false;
  ringtoneAudio: any = undefined;
  ringTimeOut: any = undefined;
  goodConnection: boolean = true;
  isMobile: any;

  @Input() highlightNotifications: boolean = false;
  @Input() showProductionLabel: any = undefined;
  @Input() showExit: boolean = false;
  @Input() roomData: any = undefined;
  @Input() pageTitle: any = undefined;
  @Input() sessionData: any = undefined;
  @Input() pushedTileLink: string = undefined;
  @Input() showEndSession: boolean = false;

  @Output() highlightNotificationsChange = new EventEmitter<boolean>();
  @Output() onendSession = new EventEmitter<any>();

  @ViewChild('ringStream') set playerRef(ref: ElementRef<HTMLAudioElement>) {
    this.ringtoneAudio = ref.nativeElement;
  }

  constructor(
    private layoutUtilsService: LayoutUtilsService,
    private requestService: RequestService,
    public router: Router,
    private translate: TranslateService,
    public dialog: MatDialog,
    private zone: NgZone,
    private route: ActivatedRoute,
    public changeDetectorRef: ChangeDetectorRef,
    private deviceService: DeviceDetectorService
  ) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.isMobile = this.deviceService.isMobile();
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.showExit = true;
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          if (this.selectedUser.hasOwnProperty('chat_dnd'))
            this.isDnD = this.selectedUser['chat_dnd'];
        }
      })
    );
    this.subscriptions.push(
      this.requestService.pageOrganization.subscribe((data) => {
        if (data) {
          this.donateLink = data.donate;
        }
      })
    );

  }

  ngOnDestroy() {
    this.subscriptions.forEach((el) => el.unsubscribe());

  }

  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  goHome() {
    this.router.navigate(['/teacher-dashboard']);
  }

  onLoggedout() {
    this.checkForTrainings();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }

  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1000px',
      data: {
        dataType: 'resource/user',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        //console.log('result', result);
      }
    });
  }
  endSession() {
    this.onendSession.emit(true);
  }
  showFrame(iframeLink: string) {
    if (iframeLink) {
      // this.zone.run(() => {
      if (this.iframeDialog) {
        this.iframeDialog.close();
      }
      this.iframeDialog = this.dialog.open(ModalIframeDialogComponent, {
        data: {
          data: iframeLink,
          className: 'dialog-frame',
          iframeHeight: '280px',
        },
      });
      this.iframeDialog.disableClose = true;

      // });
      this.iframeDialog.afterClosed().subscribe(() => {
        this.highlightNotifications = false;
        this.highlightNotificationsChange.emit(false);
      });
    }
  }

  gotoBook() {
    this.router.navigate(['/book/']);
  }

  openGlobalChat() {
    this._isOpenGlobalChat = !this._isOpenGlobalChat;
    this.detectChanges();
  }

  openAgenda() {
    this.router.navigateByUrl('/agenda');

  }
  openSponsors() {
    this.router.navigateByUrl('/sponsors');
  }
  openDonate() {
    if (this.donateLink && this.donateLink !== '') {
      window.open(this.donateLink, '_blank');
    }
  }

  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }

  toggleDnD() {
    this.requestService.saveData(
      'resource/user',
      { _id: this.selectedUser['_id'], chat_dnd: !this.isDnD },
      (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(
            this.translate.instant('Error:') + error,
            this.translate.instant('Dismiss')
          );
        }
        if (data) {
          this.isDnD = !this.isDnD;
          this.requestService.updateUserData('chat_dnd', this.isDnD);
          this.detectChanges();
          //console.log(this.selectedUser)
          this.layoutUtilsService.showNotification(
            this.translate.instant('Preferences Saved'),
            this.translate.instant('Dismiss')
          );
        }
        // this.loading = false;
      },
      true
    );
  }

  closeDrawer() {
    this.drawerState = 'in';
  }

  chatWithAttendee() {
  }

  private slideDrawer() {
    this.drawerState = 'out';
    setTimeout(() => {
      this.drawerState = 'in';
    }, 3000);
    this.detectChanges();
  }

  playRingTone() {
    this.ringtoneAudio.oncanplay = () => {
      if (!this.ringTimeOut) this.ringTimeOut = setTimeout(() => this.declineCall(), 12000);
    };
    this.ringtoneAudio.load();
    this.ringtoneAudio.play();
  }

  pauseRingTone() {
    if (this.ringtoneAudio) {
      this.ringtoneAudio.pause();
      // this.ringtoneAudio = undefined;
      clearTimeout(this.ringTimeOut);
      this.ringTimeOut = undefined;
    }
  }

  declineCall() {

  }

  answerCall() {

  }

  checkForTrainings() {
    let today = moment().utc().startOf('day');
    let filters = {
      $and: [{ userId: { $eq: this.selectedUser._id } }, { startDate: { $gte: today } }],
    };

    this.requestService.getDataList(
      'userprocess',
      {
        fieldKeys: ['userId', 'strategies', 'startDate', 'endDate', 'totalPoints', 'characters'],
        orderDir: 'asc',
        orderBy: 'endDate',
        filter: filters,
      },
      (data, error) => {
        if (error) {
          this.layoutUtilsService.showNotification(
            'No ' + this.translate.instant('training data') + ' available',
            'Dismiss'
          );
        }
        if (data) {
          if (data.results.length > 0) {
            this.checkIfDeqDone();
          } else {
            this.requestService.logOutApi();
          }
        } else {
          this.requestService.logOutApi();
        }
      }
    );
  }

  checkIfDeqDone() {
    var start = moment().utc().startOf('day'); // set to 12:00 am today UTC
    var end = moment().utc().endOf('day'); // set to 23:59 pm today UTC
    let filters = {
      $and: [{ 'userId._id': { $eq: this.selectedUser._id } }, { date: { $gte: start, $lt: end } }],
    };
    let filterObj = {
      page: 0,
      fieldKeys: ['userId', 'date', 'question1', 'question2', 'question3'],
      orderDir: 'desc',
      orderBy: 'date',
      filter: filters,
    };
    this.requestService.getDataList('deq', filterObj, (data, error) => {
      if (error) {
        this.layoutUtilsService.showNotification(
          this.translate.instant('Error:') + error,
          this.translate.instant('Dismiss')
        );
      }
      if (data) {
        if (data.results.length == 0) {
          this.requestService.getMetaData('deq', [], (data, error) => {
            if (data) {
              var originalTableSetting = Object.assign({}, data.results);
              const copiedItem = JSON.parse(JSON.stringify(originalTableSetting));
              var tableSetting = copiedItem;
              tableSetting['target'] = 'self';
              tableSetting['customSettings'] = {
                organizationId: {
                  visible: false,
                  value: this.requestService.orgId,
                },
                appId: {
                  visible: false,
                  value: [
                    {
                      _id: this.requestService.appId,
                      organizationId: this.requestService.orgId,
                      name: '',
                    },
                  ],
                },
                userId: {
                  visible: false,
                  value: { _id: this.selectedUser._id },
                },
                date: {
                  visible: false,
                  value: moment.utc(),
                },
              };
              const dialogRef = this.dialog.open(ModalDialogComponent, {
                width: '900px',
                data: {
                  dataType: 'deq',
                  dataTypeTitle: '',
                  title:
                    'Looks like the school day is ending, before the final bell rings, please tell us...',
                  data: {},
                  modalSetting: Object.assign({}, tableSetting),
                },
              });
              dialogRef.afterClosed().subscribe((result) => {
                if (result) {
                  this.requestService.logOutApi();
                }
              });
            } else {
              this.layoutUtilsService.showNotification('Something is Wrong', 'Dismiss');
            }
          });
        } else {
          this.requestService.logOutApi();
        }
      }
    });
  }
}

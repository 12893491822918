<div class="col-xl-12" *ngIf="data">
	<div mat-dialog-content *ngIf="data.confirmData">
		<div *ngIf="fieldsDetails">
			<div class="mb-20 profile-page-header" fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
				<div fxFlex>
					<figure class="card-profile-image">
						<img style="max-height: 300px;" [src]="pictureLink"
							class="circle z-depth-2 responsive-img activator">
					</figure>
				</div>
				<div fxFlex class="topData topDataName">
					<div class="dataHead">{{data.confirmData['firstName']}} {{data.confirmData['lastName']}}</div>
					<!-- <div class="dataHead">{{fieldsDetails['nickName']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['nickName']}}</span></div> -->
					<!-- <div>({{data.confirmData['userName']}})</div> -->
					<div class="dataHead">{{fieldsDetails['resources']['displayName'] | translate}}:
						<div class="inlineText" *ngFor="let lang of data.confirmData['resources'];let index = index">
							{{data.confirmData['resources'][index]['name']}} <span
								*ngIf="index < data.confirmData['resources'].length - 1">, </span></div>
					</div>
					<div class="dataHead">{{fieldsDetails['email']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['email']}}</span></div>
					<!-- <div class="dataHead" *ngIf="fieldsDetails.hasOwnProperty('phone')">{{fieldsDetails['phone']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['cell']}}</span></div> -->
					<!-- <div class="dataHead" *ngIf="fieldsDetails.hasOwnProperty('cell')">{{fieldsDetails['cell']['displayName'] | translate}}: <span
							class="inlineText">{{data.confirmData['cell']}}</span></div> -->
					<!-- <div class="dataHead">{{fieldsDetails['companyName']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['companyName']}}</span></div>
							<div class="dataHead">{{fieldsDetails['position']['displayName'] | translate}}: <span class="inlineText">{{data.confirmData['position']}}</span></div> -->
				</div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<div class="mb-20 btnList" fxLayout="row" fxLayout.lt-md="column" fxFlex
			*ngIf="data.modalSetting && !data.modalSetting.hasOwnProperty('footer')">
			<div fxFlex>
				<button color="danger" mat-raised-button
					(click)="closeModal(undefined)">{{'Close' | translate}}</button>
				<!-- <button *ngIf="data.profile" mat-raised-button [routerLinkActive]="'active'" [routerLink]="['/profile']" (click)="closeModal(undefined)">
			        {{'Edit' | translate}}
			    </button> -->
				<button [hidden]="!data.canEdit" *ngIf="data.profile" mat-raised-button [routerLinkActive]="'active'"
					[routerLink]="['/profile']" (click)="closeModal(undefined)">
					{{'Edit and Preferences' | translate}}
				</button>

				<!-- <button *ngIf="!data.profile" mat-raised-button [routerLinkActive]="'active'" [routerLink]="['/admin/user/edit/' + data.data._id]" (click)="closeModal(undefined)">
			        Edit
			    </button> -->
				<button [hidden]="!data.canEdit" *ngIf="!data.profile" mat-raised-button (click)="closeModal('edit')">
					{{'Edit' | translate}}
				</button>
			</div>
			<div fxFlex style="text-align: right;" *ngIf="data.profile">
				<button [hidden]="!data.canEdit" mat-raised-button (click)="changePassword()">
					Change Password
				</button>
			</div>
			<div [hidden]="!data.canEdit" fxFlex style="text-align: right;" *ngIf="!data.profile">
				<button mat-raised-button type="button" [disabled]="loading" (click)="resetPassword()">
					{{'Reset Password' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>

<div class="container">
  <div class="row">
    <div class="col col-30">dummy text</div>
    <div class="col col-70">
      <div class="slider-container">
        <span class="label">{{ satisfactionDescription1 }}</span>
        <input
          type="range"
          name="slider1"
          (mousemove)="changeValue1()"
          [(ngModel)]="satisfactionLevel1"
          #slider1
        />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col col-30">dummy text 2</div>
    <div class="col col-70">
      <div class="slider-container">
        <span class="label">{{ satisfactionDescription2 }}</span>
        <input
          type="range"
          name="slider2"
          (mousemove)="changeValue2()"
          [(ngModel)]="satisfactionLevel2"
          #slider2
        />
      </div>
    </div>
  </div>
  <div class="bottom-nav">
    <button mat-button>Finish <mat-icon>trending_flat</mat-icon></button>
  </div>
</div>

import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";

@Component({
  selector: "app-emoji-form",
  templateUrl: "./emoji-form.component.html",
  styleUrls: ["./emoji-form.component.scss"],
})
export class EmojiFormComponent implements OnInit {
  @ViewChild("slider1") slider1: ElementRef;
  @ViewChild("slider2") slider2: ElementRef;
  satisfactionDescription1: string = "Very disappointed";
  satisfactionDescription2: string = "Very disappointed";
  satisfactionLevel1: number = 0;
  satisfactionLevel2: number = 0;
  constructor() {}

  ngOnInit(): void {}

  changeValue1() {
    let el: HTMLInputElement = this.slider1.nativeElement;

    this.reactToVal(el.value, el);
  }
  changeValue2() {
    let el: HTMLInputElement = this.slider2.nativeElement;
    this.reactToVal(el.value, el);
  }

  reactToVal(val, slider) {
    if (val < 15) {
      slider.classList = ["very-sad"];
      slider.style.background = `linear-gradient(
          90deg,
          rgba(78, 134, 234,${val / 100 + 0.1}) ${val}%,
          rgb(214, 214, 214) ${val}%
        )`;

      if (slider.name == "slider1") {
        this.satisfactionDescription1 = "Very disappointed";
      }

      if (slider.name == "slider2") {
        this.satisfactionDescription2 = "Very disappointed";
      }

      return;
    }

    if (val >= 15 && val < 25) {
      slider.classList = ["sad"];
      slider.style.background = `linear-gradient(
          90deg,
          rgba(78, 134, 234,${val / 100}) ${val}%,
          rgb(214, 214, 214) ${val}%
        )`;

        if (slider.name == "slider1") {
          this.satisfactionDescription1 = "Disappointed";
        }
  
        if (slider.name == "slider2") {
          this.satisfactionDescription2 = "Disappointed";
        }

      return;
    }

    if (val >= 25 && val < 50) {
      slider.classList = ["happy"];
      slider.style.background = `linear-gradient(
          90deg,
          rgba(78, 134, 234,${val / 100}) ${val}%,
          rgb(214, 214, 214) ${val}%
        )`;

        if (slider.name == "slider1") {
          this.satisfactionDescription1 = "OK";
        }
  
        if (slider.name == "slider2") {
          this.satisfactionDescription2 = "OK";
        }

      return;
    }

    if (val >= 50 && val < 90) {
      slider.classList = ["very-happy"];
      slider.style.background = `linear-gradient(
          90deg,
          rgba(78, 134, 234,${val / 100}) ${val}%,
          rgb(214, 214, 214) ${val}%
        )`;

        if (slider.name == "slider1") {
          this.satisfactionDescription1 = "Satisfied";
        }
  
        if (slider.name == "slider2") {
          this.satisfactionDescription2 = "Satisfied";
        }
      return;
    }

    if (val >= 90) {
      slider.classList = ["love"];
      slider.style.background = `linear-gradient(
          90deg,
          rgb(78, 134, 234) ${val}%,
          rgb(214, 214, 214) ${val}%
        )`;

        if (slider.name == "slider1") {
          this.satisfactionDescription1 = "Very satisfied";
        }
  
        if (slider.name == "slider2") {
          this.satisfactionDescription2 = "Very satisfied";
        }
      return;
    }
  }
}

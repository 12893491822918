import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Subject } from "rxjs";
import { ModelerData } from "src/app/model/Modeler-interface";
import { LayoutUtilsService } from "./utils/layout-utils.service";
import { MeanResponse } from "../../model/mean-response";
@Injectable({
  providedIn: "root",
})
export class MeanrequestService {
  private modelers: ModelerData[];
  private modelerUpdated = new Subject<ModelerData[]>();
  private createdModelerId = new Subject<Object>();
  private url: string = "http://localhost:3000/api/modeler";
  constructor(private http: HttpClient, private layoutUtils: LayoutUtilsService) {}

  public getModelers() {
    this.http.get(this.url).subscribe((res: ModelerData[]) => {
      this.modelers = res;
      this.modelerUpdated.next([...this.modelers]);
    });
  }

  getOpeningActs() {}

  getCharacters() {}

  getStrategies() {
    return ["S 1", "S 2", "S 3", "S 4"];
  }

  getModelerUpdateListener() {
    return this.modelerUpdated.asObservable();
  }

  getItemCreatedID() {
    return this.createdModelerId.asObservable();
  }

  public deleteModeler(id) {
    this.http.delete(`${this.url}/${id}`).subscribe((res) => {
      this.getModelers();
    });
  }

  public createNewModeler(data) {
    return this.http.post(`${this.url}`, data).subscribe((res: MeanResponse) => {
      if (res.status == "success") {
        this.getModelers();
        this.layoutUtils.showNotification("added modeler successfully", "dismiss");
        this.createdModelerId.next(res.result._id);
      }

      if (res.status == "failed") {
        this.layoutUtils.errorElement("something went wrong", res.error);
      }
    });
  }

  /**
   * Updates a document using id and return a subscribable object
   * @param data updated post data
   * @returns subscribable object with a result
   */
  public updateModelerData(data) {
    return this.http.patch(`http://localhost:3000/api/modeler/${data._id}`, data);
  }
}

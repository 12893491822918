import { Component, OnInit, ViewChild, ElementRef, StaticClassProvider } from "@angular/core";
import Modeler from "./../../../../../model/modeler";
import { MatDialog } from "@angular/material/dialog";
import { NewModelerStepDialogComponent } from "../components/new-modeler-step-dialog/new-modeler-step-dialog.component";
import { EditModelerDialogComponent } from "../components/edit-modeler-dialog/edit-modeler-dialog.component";
import { DeleteModelerDialogComponent } from "../components/delete-modeler-dialog/delete-modeler-dialog.component";
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute, Router } from "@angular/router";
import { LayoutUtilsService, MeanrequestService } from "src/app/shared";
import { NewModelerDialogComponent } from "../components/new-modeler-dialog/new-modeler-dialog.component";
import { RootModelerEditDialogComponent } from "../components/root-modeler-edit-dialog/root-modeler-edit-dialog.component";
import { FlowChart } from "src/app/model/flowchart";

@Component({
  selector: "app-modeler-edit",
  templateUrl: "./modeler-edit.component.html",
  styleUrls: ["./modeler-edit.component.scss"],
})
export class ModelerEditComponent implements OnInit {
  @ViewChild("contextMenu") ctxMenu: ElementRef;

  isMenuOpen: boolean;
  nodeId: string | number;
  selectedNode;
  dataModified: boolean = false;
  savedLocally: boolean = true;

  linkList: {}[] = [];
  nodeList: {}[] = [];
  flowchart: FlowChart;

  //dummy data
  dss = {};

  ds;

  //modeler model
  modeler: Modeler;

  //changes condition for undo button activation
  public hasChanges: boolean = false;

  //fullscreen condition
  public isFullScreen: boolean = false;

  constructor(
    public deleteModelerDialog: MatDialog,
    public viewModelerDialog: MatDialog,
    public editModelerDialog: MatDialog,
    public newModelerDialog: MatDialog,
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private layoutUtils: LayoutUtilsService,
    private meanReq: MeanrequestService
  ) {
    this.isMenuOpen = false;
  }

  ngOnInit() {
    let id = this.route.snapshot.params.id;
    this.buildModelerView(id);
  }

  buildModelerView(id) {
    //TODO: subscribe to subject from service instead
    this.http.get(`http://localhost:3000/api/modeler/${id}`).subscribe((res: any) => {
      this.dss = { ...res.data };
      this.modeler = new Modeler(this.dss, false);
      this.flowchart = new FlowChart(res.data);
      this.ds = this.modeler.getDs();
    });
  }

  buildModelerViewFromLocalFile(data) {
    this.dss = { ...data };
    this.modeler = new Modeler(this.dss, false);
    this.ds = this.modeler.getDs();
    this.updateView();
  }

  /**
   * @description reacts to the left click event
   * @param e Triggered mouse event
   * @param nodeData - data of the node that the event was triggered on
   */
  selectNode(e, nodeData) {
    e.preventDefault();
    let x = e.clientX;
    let y = e.clientY;
    this.showContextMenu(x, y, this.isMenuOpen);
    this.selectedNode = nodeData;
  }

  /**
   *@description Opens context menu
   * @param x horizontal position relative to the dom
   * @param y vertical position relative to the dom
   * @param isVisible check if the element is already visible
   */
  showContextMenu(x: number, y: number, isVisible: boolean) {
    //FIXME: if mouse position is at the bottom of the screen
    //check if the menu is open
    if (isVisible) {
      //change position and current node id
      this.hideContextMenu();
      this.ctxMenu.nativeElement.style.left = x + "px";
      this.ctxMenu.nativeElement.style.top = y + "px";
      this.ctxMenu.nativeElement.classList.remove("hidden");
      return;
    }
    //else set position and show menu
    this.ctxMenu.nativeElement.style.left = x + "px";
    this.ctxMenu.nativeElement.style.top = y + "px";
    this.ctxMenu.nativeElement.classList.remove("hidden");
    this.isMenuOpen = true;
  }

  /**
   * @description Hides context menu
   */
  hideContextMenu(): void {
    this.ctxMenu.nativeElement.classList.add("hidden");
    this.isMenuOpen = false;
    //empties the selected node variable
    this.selectedNode = null;
  }

  /**
   * Checks if the context menu is open to hide it
   */
  isContextMenuOpen(): void {
    if (this.isMenuOpen) {
      this.hideContextMenu();
    }
  }

  /**
   * @description change the data source local object
   */
  updateView() {
    this.ds = this.modeler.getDs();

    if (!this.dataModified) {
      this.dataModified = true;
    }
    this.hasChanges = this.modeler.hasChangeHistory();
  }

  viewNodeData() {
    console.log(this.selectedNode);
    this.hideContextMenu();
  }

  /**
   * @description calls a Modeler class method that changes the data according to the id of the object
   * @param newData replica of the current selected node with changed values
   */
  editNodeData(newData) {
    //call class method
    this.modeler.updateChildNode(newData);
    //hide context menu
    this.hideContextMenu();
    //tell view that data modified locally
    this.savedLocally = false;
    //update the view after data change
    this.updateView();
  }

  /**
   * @description Add a child node to the selected node
   */
  addChildNode(newData) {
    //call class method
    this.modeler.addChildNode(this.selectedNode, newData);
    //hide context menu
    this.hideContextMenu();
    //tell view that data modified locally
    this.savedLocally = false;
    //update the view after data change
    this.updateView();
  }

  /**
   * @description Delete the current selected node or removes the answer from the question
   */
  deleteNode() {
    //call class method
    this.modeler.removeChildNode(this.selectedNode);
    //hide context menu
    this.hideContextMenu();
    //tell view that data modified locally
    this.savedLocally = false;
    //update the view after data change
    this.updateView();
  }

  openViewDialog() {}

  openEditDialog() {
    //check if editing is on the root level
    if (!this.selectedNode._id.includes("ANS_") && !this.selectedNode._id.includes("STP_")) {
      const rootDialog = this.newModelerDialog.open(RootModelerEditDialogComponent, {
        data: this.modeler.getData(),
        width: "500px",
        minWidth: 400,
      });
      rootDialog.afterClosed().subscribe((res) => {
        if (res) {
          this.modeler.updateParentNode(res);
          this.hideContextMenu();
          this.updateView();
        }
      });
      return;
    }
    //check if the node is an answer to change the argument value
    if (Modeler.getNodeType(this.selectedNode._id) == "answer") {
      let data = this.modeler.getStepData(this.selectedNode._id, true, this.selectedNode.parent_id);
      const dialogRef = this.newModelerDialog.open(EditModelerDialogComponent, {
        data: data,
        minWidth: 400,
        width: "500px",
      });

      dialogRef.afterClosed().subscribe((res) => {
        if (res) this.editNodeData(res);
      });

      return;
    }

    const dialogRef = this.newModelerDialog.open(EditModelerDialogComponent, {
      data: this.selectedNode,
      minWidth: 400,
      width: "500px",
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.editNodeData(res);
    });
  }

  /**
   * @description opens a dialog to insert and calls a class method to  add a new step the data
   * source from the inserted data
   */
  openNewDialog(): void {
    //TODO: refactor
    if (
      (this.selectedNode.hasOwnProperty("next_step") && this.selectedNode.next_step != "") ||
      (this.selectedNode.hasOwnProperty("children") && this.selectedNode.children.length > 0)
    ) {
      let confirmation = this.layoutUtils.alertActionElement(
        "Alert",
        "This step already has children, if you choose to continue the children will be replaced with the child you add now!",
        { btnText1: "confirm", btnText2: "cancel" }
      );
      confirmation.afterClosed().subscribe((conf) => {
        if (conf.value == "confirm") {
          const dialogRef = this.newModelerDialog.open(NewModelerStepDialogComponent, {
            data: {},
            minWidth: 400,
            width: "500px",
          });
          dialogRef.afterClosed().subscribe((res) => {
            if (res) this.addChildNode(res);
          });
        }
      });
      return;
    }
    const dialogRef = this.newModelerDialog.open(NewModelerStepDialogComponent, {
      data: {},
      minWidth: 400,
      width: "500px",
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) this.addChildNode(res);
    });
  }

  /**
   * @description opens a new dialog to confirm removal of the selected node
   */
  openDeleteDialog() {
    const dialogRef = this.deleteModelerDialog.open(DeleteModelerDialogComponent, {
      data: this.selectedNode,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) return this.deleteNode();
      this.hideContextMenu();
    });
  }

  /**
   * @description generates a title for a node based on it's id or type
   * @param nodeData node data to to generate title from
   * @returns {string} title string
   */
  createNodeTitle(nodeData): string {
    //check if node is an answer
    if (nodeData.hasOwnProperty("_id") && nodeData._id.includes("ANS_")) {
      return `Answer ${nodeData._id.replace(/ANS_/gi, "")}`;
    }

    if (nodeData.hasOwnProperty("_id")) {
      return `Step ${nodeData._id.replace(/STP_/gi, "")}`;
    }

    return nodeData.name;
  }

  /**
   * @description return a detail string for a node based on it's type or id
   * @param nodeData node data used to generate the detail string from
   * @returns {string} description of the data inside a node
   */
  createNodeDetail(nodeData): string {
    //check if the step has a type property
    if (nodeData.hasOwnProperty("type")) {
      if (nodeData.type == "question") {
        return nodeData.question_text;
      }

      if (nodeData.type == "tiles") {
        return nodeData.tile_url;
      }

      if (nodeData.type == "display") {
        return "type -> Display";
      }
    }

    //check if the step has an answer text property to return the answer text as a description
    if (nodeData.hasOwnProperty("answer_text")) {
      return nodeData.answer_text;
    }

    //else returns the node title
    return nodeData.title;
  }

  /**
   * @description returns an html element with specific classes based
   * on the type of the node
   * @param data data of the node at initialization
   * @returns {String} html element in string format
   */
  generateHtmlFromData(data): string {
    //check if the data has a type
    if (data.hasOwnProperty("type")) {
      if (data.type == "question") {
        return `<div class="title question">
        ${this.createNodeTitle(data)}
        </div> <div class="content question">${this.createNodeDetail(data)}</div>`;
      }
      if (data.type == "tiles") {
        return `<div class="title tiles">
        ${this.createNodeTitle(data)}
        </div> <div class="content tiles">${this.createNodeDetail(data)}</div>`;
      }
      if (data.type == "display") {
        return `<div class="title display">
        ${this.createNodeTitle(data)}
        </div> <div class="content display">${this.createNodeDetail(data)}</div>`;
      }
    }

    //check if the data is an answer
    if (data._id.includes("ANS_")) {
      return `<div class="title answer">
      ${this.createNodeTitle(data)}
      </div> <div class="content answer">${this.createNodeDetail(data)}</div>`;
    }

    //return element with title and content of root node
    return `<div class="title">${
      data.title ? data.title : "Root node"
    }</div> <div class="content">Root Node</div>`;
  }

  saveChangesLocally() {
    localStorage.setItem("currentModeler", JSON.stringify(this.modeler.getData()));
    this.savedLocally = true;
  }

  saveAndClose() {
    let a = this.layoutUtils.alertActionElement(
      "Save Changes",
      "Are you sure you want to save your recent modifications?",
      { btnText1: "confirm", btnText2: "cancel" }
    );
    a.afterClosed().subscribe((res) => {
      if (res.value == "confirm") {
        let data = { ...this.modeler.getData() };
        this.meanReq.updateModelerData(data).subscribe((res: any) => {
          if (res.status == "success") {
            this.layoutUtils.showNotification("Modeler updated successfully!", "close");
            this.router.navigateByUrl("/admin/modeler-list");
            localStorage.removeItem("currentModeler");
            return;
          }

          if (res.status !== "success") {
            this.layoutUtils.errorElement("something went wrong!", res.error);
          }
        });
      }
    });
    return;
  }

  exitEditor() {
    if (this.dataModified) {
      this.layoutUtils
        .alertActionElement(
          "Exit editor",
          "Are you sure you want to exit without saving changes?",
          { btnText1: "Yes", btnText2: "no" }
        )
        .afterClosed()
        .subscribe((res) => {
          if (res.value == "no") {
            return;
          }
          this.router.navigateByUrl("/admin/modeler-list");
          //remove locally saved edit
          localStorage.removeItem("currentModeler");
        });
      return;
    }
    this.router.navigateByUrl("/admin/modeler-list");
  }

  undoChanges() {
    this.modeler.undoLastChange();
    this.updateView();
  }

  toggleFullScreenMode() {
    const docBody = document.body as HTMLElement & {
      mozRequestFullScreen(): Promise<void>;
      webkitRequestFullscreen(): Promise<void>;
      msRequestFullscreen(): Promise<void>;
    };
    const doc = document as Document & {
      mozCancelFullScreen(): Promise<void>;
      webkitExitFullscreen(): Promise<void>;
      msExitFullscreen(): Promise<void>;
    };
    console.log(this.isFullScreen);
    //assign native element to a variable
    if (this.isFullScreen == false) {
      this.isFullScreen = true;
      if (docBody.requestFullscreen) {
        docBody.requestFullscreen();
        return;
      }
      //Firefox
      if (docBody.mozRequestFullScreen) {
        docBody.mozRequestFullScreen();
        return;
      }
      //Chrome, Safari and Opera
      if (docBody.webkitRequestFullscreen) {
        docBody.webkitRequestFullscreen();
        return;
      }
      //IE/Edge
      if (docBody.msRequestFullscreen) {
        docBody.msRequestFullscreen();
        return;
      }
      return;
    }
    this.isFullScreen = false;
    if (doc.exitFullscreen) {
      doc.exitFullscreen();
      return;
    }
    //Firefox
    if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen();
      return;
    }
    //Chrome, safari and Opera
    if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen();
      return;
    }
    if (doc.msExitFullscreen) {
      doc.msExitFullscreen();
      return;
    }
  }

  notifyLocalFile(id) {
    if (localStorage.getItem("currentModeler")) {
      let ds = JSON.parse(localStorage.getItem("currentModeler"));
      if (ds._id == ds._id) {
        this.layoutUtils
          .alertActionElement(
            "Alert",
            "You have changes made to modeler saved localy but neve Upladed, do you want to load them now?",
            { btnText2: "cancel", btnText1: "yes" }
          )
          .afterClosed()
          .subscribe((res) => {
            if (res.value == "yes") {
              this.loadDataFromLocalFile();
            }
          });
      }
    }
  }

  loadDataFromLocalFile() {
    let modelerData = JSON.parse(localStorage.getItem("currentModeler"));
    this.buildModelerViewFromLocalFile(modelerData);
  }
}

import { Component, Inject, OnInit, ChangeDetectorRef } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { RequestService, LayoutUtilsService, LoaderService } from "../../../shared/services";
import { urlSafeBase64Encoding } from "../../../shared/helpers";
import { FormControl, FormGroupDirective, NgForm, FormGroup } from "@angular/forms";
import { fromEvent, BehaviorSubject, merge, Subscription } from "rxjs";
import { ErrorStateMatcher } from "@angular/material/core";
import { TileSelectDialogComponent } from "../../../shared/components/tile-select-dialog/tile-select-dialog.component";
import { CustomSelectDialogComponent } from "../../../shared/components/custom-select-dialog/custom-select-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { DatePipe } from "@angular/common";
import { Moment } from "moment";
import * as moment from "moment";
import { EmojiSliderFieldComponent } from "../../../shared/components/emoji-slider-field/emoji-slider-field.component";

export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

export interface DialogData {
  dataType: string;
  dataTypeTitle: string;
  title: string;
  data: any;
  modalSetting: any;
  confirmData: any;
}

@Component({
  selector: "app-dialog-modal",
  templateUrl: "./custom-dialog.component.html",
  styleUrls: ["./custom-dialog.component.scss"],
})
export class ModalDialogComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public errorMessage: string = "";
  public selectedUser: any;
  public isAdmin: boolean = false;
  public loading: boolean = false;
  public hasFormErrors: boolean = false;
  public esMatcher = new MyDialogErrorStateMatcher();
  public isSubmitted: boolean = true;
  public metaFieldSetting = undefined;
  public allowedExtensions: string[] = ["jpeg", "jpg", "bmp", "png"];
  constructor(
    private translate: TranslateService,
    private changeDetectorRefs: ChangeDetectorRef,
    private requestService: RequestService,
    public dialog: MatDialog,
    private layoutUtilsService: LayoutUtilsService,
    public dialogRef: MatDialogRef<ModalDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    //console.log('DialogData', data);
  }
  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.isAdmin = this.requestService.isUserRoleAdmin();
          this.buildSetting();
        }
      })
    );
  }
  private buildSetting() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = "";
      this.data.modalSetting.fields = [];

      let orgId = undefined;
      let lang = undefined;
      if (this.data.dataType === "resource/user") {
        orgId = this.requestService.orgId;
        lang = this.requestService.lang;
      }
      this.requestService.getMetaData(
        this.data.dataType,
        undefined,
        (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(
              this.translate.instant("Error:") + error,
              this.translate.instant("Dismiss")
            );
          }
          this.loading = false;
          if (data) {
            let newFields = data.results.fields;
            this.metaFieldSetting = this.buildMetaSetting(data.results, (parent = undefined));
            let idx = 0;
            if (this.data.modalSetting.hasOwnProperty("customSettings")) {
              for (let fld of newFields) {
                if (this.data.modalSetting.customSettings.hasOwnProperty(fld.name)) {
                  newFields[idx]["visible"] = this.data.modalSetting.customSettings[
                    fld.name
                  ].visible;
                }
                idx++;
              }
            }
            this.data.modalSetting.fields = newFields;
            if (this.data.data.hasOwnProperty("_id")) {
              this.loadData();
            } else {
              this.data.data = this.getEmptyObject();
            }
          } else {
            this.layoutUtilsService.showNotification(
              this.translate.instant("Something is Wrong"),
              this.translate.instant("Dismiss")
            );
          }
        },
        orgId,
        lang
      );
    }
  }
  buildMetaSetting(data, parent = undefined) {
    let dataObject = {};
    // let tabObject = [];
    for (let col of data.fields) {
      if ((col.editable || !col.generated) && col.type !== "object" && col.type !== "table") {
        if (parent) {
          col["inputName"] = parent + col["name"];
        }
        dataObject[col.name] = col;
      } else if (col.type === "object") {
        dataObject[col.name] = this.buildMetaSetting(col);
      } else if (col.type === "table") {
        dataObject[col.name] = col;
      }
    }
    return dataObject;
  }
  public loadData() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = "";
      let dataId = this.data.data["_id"];
      if (this.data.modalSetting.hasOwnProperty("useOrgId")) {
        dataId = dataId + "/" + this.requestService.orgId;
      }
      this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error:") + error,
            this.translate.instant("Dismiss")
          );
        }
        if (data) {
          this.data.data = this.getCleanDataObject(data.results);
        }
        this.loading = false;
      });
    }
  }
  private getCleanDataObject(currentObject) {
    let newObj = currentObject;
    for (let col of this.data.modalSetting.fields) {
      if (col.type === "datetime" && newObj.hasOwnProperty(col.name)) {
        newObj[col.name] = moment.utc(newObj[col.name]).local().format("YYYY-MM-DDTHH:mm");
      }
    }
    return newObj;
  }
  public getDateAtStamp(dt): string {
    let tempDate = new Date(dt);
    let datePipe = new DatePipe("en-US");
    let setDob = datePipe.transform(tempDate, "yyyy-MM-ddTHH:mm");
    return setDob;
  }
  public setAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setAttributeBoolean(id, val) {
    this.data.data[id] = JSON.parse(val);
  }
  public setReferenceAttribute(parentId, id, val) {
    // console.log('setRoleReferenceAttribute', parentId, id, val);
    this.data.data[parentId][id] = val;
  }
  public setMultipleReferenceAttribute(id, val) {
    this.data.data[id] = val;
  }
  public setRoleReferenceAttribute(parentId, val) {
    this.data.data[parentId] = [{ _id: val._id, name: val.name }];
  }
  public setDateTimeAttribute(id, val) {
    //console.log('setDateTimeAttribute', id, val);
    // try{
    //   this.data.data[id] = val.toISOString();
    // }catch(e){
    //   // error
    //   console.log('setDateTimeAttribute', e);
    // }
  }
  public setDateAttribute(id, val) {
    // console.log('setDateAttribute', id, val.toISOString());
    try {
      this.data.data[id] = val.toISOString();
    } catch (e) {
      // error
      //console.log('setDateAttribute', e);
    }
  }
  private getCleanObject(data) {
    let newObj = { _id: data._id };
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== "action") {
        if (col.dataType === "password") {
          newObj[col.name] = urlSafeBase64Encoding(data[col.name]);
        } else if (col.type === "reference") {
          if (col.reference.kind === "multiple") {
            if (data[col.name] && data[col.name].length > 0) newObj[col.name] = data[col.name];
          } else {
            if (data[col.name] !== "") newObj[col.name] = data[col.name];
          }
        } else if (col.type === "datetime") {
          newObj[col.name] = moment(data[col.name]).utc().format("YYYY-MM-DDTHH:mm");
        } else {
          newObj[col.name] = data[col.name];
        }
        // if (this.data.modalSetting.hasOwnProperty('customSettings')) {
        //   if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
        //     newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
        //   }
        // }
      }
    }
    return newObj;
  }
  private validateObject(data: any) {
    for (let col of this.data.modalSetting.fields) {
      if (
        !col.nullable &&
        !col.generated &&
        col.type !== "action" &&
        col.name !== "privacyterm" &&
        (col.visible || (col.admin && this.isAdmin))
      ) {
        if (col.type === "reference") {
          if (col.reference.kind === "multiple") {
            // console.log('col.name', col.name, data[col.name] );
            if (col.name === "resources") {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
              if (data[col.name][0]["_id"] === "") {
                return false;
              }
            } else {
              if (data && data[col.name] && data[col.name].length === 0) {
                return false;
              }
            }
          } else {
            if (data && data[col.name] && data[col.name]["_id"] === "") {
              // console.log('col.name', col.name, data[col.name] );
              return false;
            }
          }
        } else if (col.type === "tags") {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === "email") {
          if (
            data &&
            (data[col.name].length === 0 ||
              data[col.name] === undefined ||
              !this.isEmailValid(data[col.name]))
          ) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else if (col.type === "url") {
          if (data && (data[col.name].length === 0 || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        } else {
          if (data && (data[col.name] === "" || data[col.name] === undefined)) {
            // console.log('col.name', col.name, data[col.name] );
            return false;
          }
        }
      }
    }
    return true;
  }
  private validateLogic(data) {
    for (let col of this.data.modalSetting.fields) {
      if (
        !col.nullable &&
        !col.generated &&
        col.type !== "action" &&
        col.name !== "privacyterm" &&
        (col.visible || (col.admin && this.isAdmin))
      ) {
        if (col.hasOwnProperty("validation")) {
          for (let vald of col.validation) {
            if (vald.operator === "lt") {
              if (col.type === "datetime") {
                // console.log('data[vald.target]', data[vald.target]);
                // console.log('moment(data[col.name]', data[col.name]);
                // console.log('minutes', moment(data[vald.target]).diff(moment(data[col.name]), 'minutes'));
                if (
                  !(
                    data[col.name] &&
                    data[vald.target] &&
                    moment(data[vald.target]).diff(moment(data[col.name]), "minutes") > 0
                  )
                ) {
                  return (
                    col.displayName +
                    " should be less than " +
                    this.metaFieldSetting[vald.target]["displayName"]
                  );
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return (
                    col.displayName +
                    " should be less than " +
                    this.metaFieldSetting[vald.target]["displayName"]
                  );
                }
              }
            } else if (vald.operator === "gt") {
              if (col.type === "datetime") {
                if (
                  !(
                    data[col.name] &&
                    data[vald.target] &&
                    moment(data[col.name]).diff(moment(data[vald.target]), "minutes") > 0
                  )
                ) {
                  return (
                    col.displayName +
                    " should be greater than " +
                    this.metaFieldSetting[vald.target]["displayName"]
                  );
                }
              } else {
                if (!(data[col.name] && data[vald.target] && data[col.name] < data[vald.target])) {
                  return (
                    col.displayName +
                    " should be greater than " +
                    this.metaFieldSetting[vald.target]["displayName"]
                  );
                }
              }
            } else if (vald.operator === "url") {
              if (!this.isUrlValid(data[col.name])) {
                return col.displayName + " should url format.";
              }
            }
          }
        }
      }
    }
    return undefined;
  }
  public saveData(type) {
    // console.log('saveData', this.data.data);
    // console.log('this.data.modalSetting', this.data.modalSetting);
    // console.log('getCleanObject', this.getCleanObject(this.data.data));
    if (this.data.modalSetting.target === "self") {
      if (!this.loading) {
        let validateLogic = this.validateLogic(this.data.data);
        if (!validateLogic) {
          if (this.validateObject(this.data.data)) {
            this.loading = true;
            //  this.loaderService.display(true);
            let useOrgId = false;
            if (this.data.modalSetting.hasOwnProperty("useOrgId")) {
              useOrgId = this.data.modalSetting["useOrgId"];
            }
            this.errorMessage = "";
            this.requestService.saveData(
              this.data.dataType,
              this.getCleanObject(this.data.data),
              (data, error) => {
                if (error) {
                  this.errorMessage = error;
                  this.layoutUtilsService.showNotification(
                    this.translate.instant("Error:") + error,
                    this.translate.instant("Dismiss")
                  );
                }
                if (data) {
                  if (type === "create") {
                    this.layoutUtilsService.showNotification(
                      this.data.dataTypeTitle +
                        " " +
                        this.translate.instant("created Successfully"),
                      this.translate.instant("Dismiss")
                    );
                  } else if (type === "edit") {
                    this.layoutUtilsService.showNotification(
                      this.data.dataTypeTitle + " " + this.translate.instant("edited Successfully"),
                      this.translate.instant("Dismiss")
                    );
                  }
                  this.closeModal({ action: "refresh" });
                }
                this.loading = false;
              },
              useOrgId
            );
          } else {
            this.layoutUtilsService.showNotification(
              this.translate.instant("Error:") +
              this.translate.instant("You need to set all mandatory fields"),
              this.translate.instant("Dismiss")
            );
          }
        } else {
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error:") + this.translate.instant(validateLogic),
            this.translate.instant("Dismiss")
          );
        }
      }
    } else if (this.data.modalSetting.target === "parent") {
      let validateLogic = this.validateLogic(this.data.data);
      if (!validateLogic) {
        if (this.validateObject(this.data.data)) {
          this.closeModal({
            action: type,
            dataType: this.data.dataType,
            dataTypeTitle: this.data.dataTypeTitle,
            modalSetting: this.data.modalSetting,
            data: this.getCleanObject(this.data.data),
          });
        } else {
          this.layoutUtilsService.showNotification(
            this.translate.instant("Error: ") +
            this.translate.instant("You need to select all mandatory fields"),
            this.translate.instant("Dismiss")
          );
        }
      } else {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Error: ") + this.translate.instant(validateLogic),
          this.translate.instant("Dismiss")
        );
      }
    }
  }
  closeModal(data): void {
    this.dialogRef.close(data);
  }
  public toggleClick(action, target, data) {
    if (target === "parent") {
      if (this.validateObject(this.data.data)) {
        this.closeModal({ action: action, data: this.data.data, value: data });
      } else {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Error:") +
          this.translate.instant("You need to select all mandatory fields"),
          this.translate.instant("Dismiss")
        );
      }
    } else {
      //console.log('toggleClick Self', action, target, data);
      if (action === "close") {
        this.closeModal(undefined);
      }
    }
  }
  private getEmptyObject() {
    let newObj = {};
    for (let col of this.data.modalSetting.fields) {
      if ((col.editable || !col.generated) && col.type !== "action") {
        if (col.type === "reference") {
          if (col.reference.kind === "multiple") {
            if (col.name === "resources") {
              newObj[col.name] = [{ _id: "", name: "" }];
            } else {
              newObj[col.name] = [];
            }
          } else {
            newObj[col.name] = { _id: "", name: "" };
          }
        } else if (col.type === "boolean") {
          if (col.name == 'isPasswordRequired')
            newObj[col.name] = true;
          else
            newObj[col.name] = false;
        } else if (col.type === "color") {
          newObj[col.name] = "#ffffff";
        } else if (col.type === "picturearray") {
          newObj[col.name] = [];
        } else if (col.type === "datetime") {
          newObj[col.name] = moment.utc().format("YYYY-MM-DDTHH:mm");
        } else {
          newObj[col.name] = "";
        }
        if (this.data.modalSetting.hasOwnProperty("customSettings")) {
          if (this.data.modalSetting.customSettings.hasOwnProperty(col.name)) {
            newObj[col.name] = this.data.modalSetting.customSettings[col.name].value;
          }
        }
      }
    }
    return newObj;
  }
  private isUrlValid(url) {
    var re = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/;
    return re.test(String(url).toLowerCase());
  }
  private isEmailValid(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  public addPictures(id, url) {
    if (this.data.data.hasOwnProperty(id)) {
      this.data.data[id].push({ url: url, type: "link", link: "" });
    } else {
      this.data.data[id] = [{ url: url, type: "link", link: "" }];
    }
  }
  public setPictureArrayType(idx, id, val) {
    this.data.data[id][idx]["type"] = val;
    this.data.data[id][idx]["link"] = "";
  }
  public selectPictureLinkType(idx, id, type) {
    if (type === "tile") {
      this.editTile(idx, id);
    } else if (type === "room") {
      this.editCustomSelectDialog("room", "Room", idx, id);
    }
  }
  editTile(idx, id) {
    const dialogRef = this.dialog.open(TileSelectDialogComponent, {
      width: "600px",
      data: {
        title: this.translate.instant("Select") + " " + this.translate.instant("Tile"),
        data: {},
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.hasOwnProperty("_id")) {
          this.data.data[id][idx]["link"] = result["_id"];
        } else {
          this.data.data[id][idx]["link"] = "";
        }
      }
    });
  }
  editCustomSelectDialog(dataType, dataTypeTitle, idx, id) {
    const dialogRef = this.dialog.open(CustomSelectDialogComponent, {
      width: "600px",
      data: {
        title: this.translate.instant("Select") + " " + this.translate.instant("Billboard"),
        dataType: dataType,
        dataTypeTitle: dataTypeTitle,
        data: this.data.data[id][idx]["link"],
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (result.hasOwnProperty("_id")) {
          this.data.data[id][idx]["link"] = result["_id"];
        } else {
          this.data.data[id][idx]["link"] = "";
        }
      }
    });
  }
  public deletePictures(id, index) {
    this.data.data[id].splice(index, 1);
  }
  /**
   *  @param target: trigger event
   *
   *  trigger read files browsed files
   */
  onBrowseFiles(id, target: any): void {
    this.readFiles(id, target.files);
  }

  /**
   *  @param files: list of browsed files
   *  @param index: iterator over browsed images
   *
   *  read files browsed by user
   */
  readFiles(id, files, index = 0): void {
    // let reader = new FileReader();
    if (index in files) {
      let currentFile = {
        error: false,
        text: files[index].name,
        id: files[index].id,
        originalFile: files[index],
        source_url: null,
      };
      let fileExt = files[index].name.split(".").pop();
      const max_size = 5000000;
      if (files[index].size > max_size) {
        this.layoutUtilsService.showNotification(
          this.translate.instant("Maximum size allowed is") + " " + max_size / 1000000 + "Mb",
          this.translate.instant("Dismiss")
        );
      } else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
        currentFile.error = true;
      } else {
        this.requestService.onUploadPictureByBanner(currentFile).subscribe(
          (results: any) => {
            //console.log('results', results);
            if (results["status"]) {
              currentFile.source_url = results["results"].link;
              this.addPictures(id, results["results"].link);
              this.layoutUtilsService.showNotification(
                this.translate.instant("Successfully Uploaded"),
                this.translate.instant("Dismiss")
              );
            } else {
              currentFile.error = true;
              this.layoutUtilsService.showNotification(
                this.translate.instant("Error:") + results["message"],
                this.translate.instant("Dismiss")
              );
            }
            // this.myInputVariable.nativeElement.value = "";
            this.changeDetectorRefs.detectChanges();
            // this.currentFile = currentFile;
          },
          (error) => {
            //console.log('Error uploading file.', error);
            currentFile.error = true;
            this.layoutUtilsService.showNotification(
              this.translate.instant("Error:") +
              " " +
              this.translate.instant("Error uploading file."),
              this.translate.instant("Dismiss")
            );
            this.changeDetectorRefs.detectChanges();
          }
        );
      }
    } else {
      this.changeDetectorRefs.detectChanges();
    }
  }
  readFile(file, reader, callback): void {
    reader.onload = () => {
      callback(reader.result);
    };
    reader.readAsDataURL(file);
  }
  sliderChanged(id, val) {
    this.data.data[id] = val;
  }
}

import { each } from "lodash";

//children array enum
enum childArrayCheck {
  HAS_CHILDREN,
  NO_CHILDREN,
  HAS_NO_CHILDREN_ARRAY,
}

//answers array enum
enum answerArrayCheck {
  HAS_CHILDREN,
  NO_CHILDREN,
  HAS_NO_CHILDREN_ARRAY,
}

export default class Modeler {
  //the current object properties
  public inputHolder: any;
  //final data source
  private ds: any;
  //history array
  private changeHistory = [];

  constructor(input: any, isJSON: boolean = false) {
    let placeHolderObj: object;
    //check if the input is a JSON string
    if (isJSON) {
      //parse the json object and place in a place holder object
      placeHolderObj = JSON.parse(input);
      //reassign the initial object to the placeholder object value
      input = placeHolderObj;
    }

    //save the data to a local variable for further manipulation
    this.inputHolder = { ...input };

    //initiate the modeler object
    this.initObject(input);
  }

  /**
   * @name initObject
   * @description create an object that is readable by the chart plugin
   * @param {object} input steps object
   */
  initObject(input: any): void {
    //find the entry point of the object
    let entryPoint = +input.entry.replace(/STP_/gi, "");

    //destruct the object inside ds
    this.ds = { ...input };

    if (this.ds.hasOwnProperty("steps") && Object.keys(this.ds.steps)) {
      //add a children array that has the entry point
      this.ds.children = this.createFirstChild(entryPoint, { ...this.ds.steps });
      delete this.ds.steps;
    }

    if (!this.ds.hasOwnProperty("children") || this.ds.children.length < 1) return;
    //check if this step has children
    if (!this.ds.children[0].hasOwnProperty("is_last") || !this.ds.children[0].is_last) {
      //placeholder array to hold children
      let arr = [];

      //find and create the children of the entry point step
      //then assign the children to the placeholder array
      arr = this.findAndCreateChildArrays(this.ds.children[0]);

      //checks if the array is not empty
      if (arr) {
        this.ds.children[0].children = arr;
      }
    }

    //if the first step is the last step return
    return;
  }

  /**
   *@description find out if child has children and creates child nodes then 
   puts them in an array after that searches for the children of each child 
   in the return array
   * @param {Object} obj a step object
   * @returns {Array} array of children of the calling parent
   */
  findAndCreateChildArrays(obj) {
    //FIXME: full path generation
    //placeholder array for the returned children
    let placeholderArr = [];

    //check if the object has an answers property
    if (obj.hasOwnProperty("answers") && obj.answers) {
      //loop over each answer and push them into the placeholder array
      let childChildren = [];
      obj.answers.forEach((el, index) => {
        //add parent id for the question
        el.parent_id = obj._id;

        //assign an id to the answer element
        el._id = `ANS_${index + 1}`;

        //add full path to object
        el.full_path = el.hasOwnProperty("full_path")
          ? `${el.full_path}/${obj._id}`
          : `${el.parent_id}/${el._id}`;

        //push answers to the array
        placeholderArr.push(el);
      });

      //delete the answers property
      //FIXME: if removed adds children to the first step of this.inputHolder
      //delete obj.answers;

      //loop over each answer in the array to find and get children
      placeholderArr.forEach((el, index) => {
        //assign the returned value to a variable
        let childrenArr = this.findAndCreateChildArrays(el);

        //check if the childrenArr is empty
        if (childrenArr) {
          //create and assign a children property each element of the parent object
          placeholderArr[index].children = childrenArr;
        }
      });

      //return the children array
      return placeholderArr;
    }

    //check if the argument has a next step and return an array of the next steps
    if (obj.hasOwnProperty("next_step") && obj.next_step) {
      //second placeholder array for children of the found step
      let childArr = [];

      //placeholder object for the found step
      let placeHolderObj: any;

      //loop over the steps in the main object
      for (const key in this.inputHolder.steps) {
        //check if the current step in the loop matched next_step id
        if (this.inputHolder.steps[key]._id == `STP_${obj.next_step}`) {
          //assign the found step properties to the placeholder object
          placeHolderObj = { ...this.inputHolder.steps[key] };

          //add parent id property
          placeHolderObj.parent_id = obj._id;

          //add full_path
          placeHolderObj.full_path = obj.hasOwnProperty("full_path")
            ? `${obj.full_path}/${placeHolderObj._id}`
            : `${obj._id}/${placeHolderObj._id}`;
          //check if the found step has a next step or children
          if (
            (placeHolderObj.hasOwnProperty("next_step") && placeHolderObj.next_step != "") ||
            placeHolderObj.hasOwnProperty("answers")
          ) {
            //search for the object of the next step
            //get the children and assign them them the childArr(second children array)
            childArr = this.findAndCreateChildArrays(placeHolderObj);

            //loop over each child in the second children array and check for next steps or answers
            /**@var {Number} j index of the current element in the return children array*/
            childArr.forEach((child, j) => {
              //assign the returned value of the find and create function to a local variable
              let returnedArr = this.findAndCreateChildArrays(child);

              //if the value of the return is not null assign the children to the child
              if (returnedArr) {
                childArr[j].children = returnedArr;
              }
            });

            //if the children array is not null
            //assign the children array to a children property
            if (childArr) {
              placeHolderObj.children = childArr;
            }
          }

          //push the
          placeholderArr.push(placeHolderObj);
        }
      }

      //return the children array
      return placeholderArr;
    }

    return null;
  }

  /**
   * @description adds a new child to a selected node and increments the step count on finished
   * @param parentData data of the clicked on
   * @param newChild data of the new child
   */
  public addChildNode(parentData, newChild) {
    //add original object to change history
    this.changeHistory.push(JSON.stringify({ ...this.inputHolder }));

    if (parentData.hasOwnProperty("_id")) {
      if (!parentData._id.includes("STP_") && !parentData._id.includes("ANS_")) {
        let newStepId = `STP_${this.inputHolder.number_of_steps + 1}`;
        newChild._id = newStepId;
        newChild.parent_id = parentData._id;
        this.inputHolder.steps = {};
        this.inputHolder.steps[`step${this.inputHolder.number_of_steps + 1}`] = newChild;
        if (!this.hasEntryStep()) {
          this.inputHolder.entry = newStepId.replace(/STP_/gi, "");
        }
        this.incrementStepCount();
        this.initObject(this.inputHolder);
        return;
      }
    }
    let parentId = parentData._id;
    let newStepId = `STP_${this.inputHolder.number_of_steps + 1}`;
    //check if the type of the parent step is an answer
    if (parentId.includes("ANS_")) {
      for (const key in this.inputHolder.steps) {
        if (this.inputHolder.steps[key]._id == parentData.parent_id) {
          //extract answer index from answer id
          let answerIndex = +parentData._id.replace(/ANS_/gi, "") - 1;
          let nextStepNum = this.inputHolder.number_of_steps + 1;
          //set an id for the new step
          newChild._id = `STP_${nextStepNum}`;
          //create parent id for new child
          newChild.parent_id = parentData._id;
          //create new step
          this.inputHolder.steps[`step${this.inputHolder.number_of_steps + 1}`] = { ...newChild };
          this.inputHolder.steps[key].answers[answerIndex].is_last = false;
          this.inputHolder.steps[key].answers[answerIndex].next_step = nextStepNum;
          this.incrementStepCount();
          this.initObject(this.inputHolder);
          return;
        }
      }
      return;
    }
    //if not add a new child to the parent node
    for (const key in this.inputHolder.steps) {
      if (this.inputHolder.steps[key]._id == parentData._id) {
        delete this.inputHolder.steps[key].is_last;
        this.inputHolder.steps[key].next_step = newStepId.replace(/STP_/gi, "");
        newChild.parent_id = parentId;
        newChild._id = newStepId;
        this.inputHolder.steps[`step${this.inputHolder.number_of_steps + 1}`] = newChild;
        if (!this.hasEntryStep()) this.inputHolder.entry = newStepId;
        this.incrementStepCount();
        this.initObject(this.inputHolder);
        return;
      }
    }
  }

  /**
   * @description Gets current data source
   */
  public getDs() {
    return this.ds;
  }

  public removeChildNode(nodeData) {
    //add original object to change history
    this.changeHistory.push(JSON.stringify(this.inputHolder));

    //check if the node is an answer
    if (nodeData._id.includes("ANS_")) {
      //extract parent id
      let parentId = nodeData.parent_id;

      //extract answer index
      let answerIndex = +nodeData._id.replace(/ANS_/gi, "") - 1;

      //loop over the input steps to find the parent step
      for (const key in this.inputHolder.steps) {
        if (this.inputHolder.steps[key]._id == parentId) {
          this.inputHolder.steps[key].answers.splice(answerIndex, 1);

          //clean answers and children to prevent error while regenerating
          this.cleanChildrenProperty(this.isChildrenArrayEmpty(this.inputHolder.steps[key]), key);

          this.cleanAnswersProperty(this.isAnswersArrayEmpty(this.inputHolder.steps[key]), key);

          this.initObject(this.inputHolder);
        }
      }
      return;
    }

    //check if the node is a step
    if (nodeData._id.includes("STP_")) {
      //extract the id
      let parentId = nodeData.parent_id;

      //check if parent is an answer
      if (parentId.includes("ANS_")) {
        //create path to child
        let path = nodeData.full_path.split("/");

        //extract answer index from the nod
        let answerIndex = +nodeData.parent_id.replace(/ANS_/gi, "") - 1;

        for (const key in this.inputHolder.steps) {
          if (this.inputHolder.steps[key]._id == path[0]) {
            //set answer as last step
            this.inputHolder.steps[key].answers[answerIndex].is_last = true;

            //delete next step property of the answer
            delete this.inputHolder.steps[key].answers[answerIndex].next_step;

            //delete children property of the answer
            delete this.inputHolder.steps[key].answers[answerIndex].children;

            //clean children of the parent step of the answer
            this.cleanChildrenProperty(this.isChildrenArrayEmpty(this.inputHolder.steps[key]), key);

            //regenerate the document
            this.initObject(this.inputHolder);
          }
        }
        return;
      }
      //loop over the input steps to find the parent
      for (const key in this.inputHolder.steps) {
        if (this.inputHolder.steps[key]._id == parentId) {
          //set as last step
          this.inputHolder.steps[key].is_last = true;
          //delete the next step property to prevent error
          delete this.inputHolder.steps[key].next_step;

          //clean answers and children to prevent error while regenerating
          this.cleanChildrenProperty(this.isChildrenArrayEmpty(this.inputHolder.steps[key]), key);
          this.cleanAnswersProperty(this.isAnswersArrayEmpty(this.inputHolder.steps[key]), key);

          //regenerate the document
          this.initObject(this.inputHolder);
        }
      }
    }
  }

  private createFirstChild(entry, data) {
    //children array to return
    let arr = [];
    //find the first child
    if (Object.keys(data).length > 0) {
      for (const key in data) {
        if (data[key]._id == `STP_${entry}`) {
          //push it to the placeholder array
          arr.push(data[key]);
          return arr;
        }
      }
    }

    //if not found return empty array
    return [];
  }

  private isChildrenArrayEmpty(node): number {
    //check if the node has a children property
    if (node.hasOwnProperty("children")) {
      if (node.children.length < 1) {
        //return child has children
        return childArrayCheck.NO_CHILDREN;
      }
      //return child has children
      return childArrayCheck.HAS_CHILDREN;
    }
    //return no has no children array
    return childArrayCheck.HAS_NO_CHILDREN_ARRAY;
  }

  private isAnswersArrayEmpty(node): number {
    //check if the node has a children property
    if (node.hasOwnProperty("answers")) {
      if (node.answers.length < 1) {
        //return child has children
        return answerArrayCheck.NO_CHILDREN;
      }
      //return child has children
      return answerArrayCheck.HAS_CHILDREN;
    }
    //return no has no children array
    return answerArrayCheck.HAS_NO_CHILDREN_ARRAY;
  }

  private cleanChildrenProperty(i: {}, key: string): void {
    if (i == childArrayCheck.HAS_CHILDREN) {
      delete this.inputHolder.steps[key].children;
      return;
    }
    if (i == childArrayCheck.NO_CHILDREN) {
      delete this.inputHolder.steps[key].children;
      return;
    }
    if (i == childArrayCheck.HAS_NO_CHILDREN_ARRAY) {
      return;
    }
  }

  private cleanAnswersProperty(i: {}, key: string): void {
    if (i == answerArrayCheck.HAS_CHILDREN) {
      return;
    }
    if (i == childArrayCheck.NO_CHILDREN) {
      delete this.inputHolder.steps[key].answers;
      delete this.inputHolder.steps[key].next_step;
      this.inputHolder.steps[key].is_last = false;
      return;
    }
    if (i == childArrayCheck.HAS_NO_CHILDREN_ARRAY) {
      return;
    }
  }

  /**
   * @description increment number_of_steps property for id and step name generation
   */
  private incrementStepCount() {
    this.inputHolder.number_of_steps = this.inputHolder.number_of_steps + 1;
  }

  /**
   * @description updated a node based on the data given in the argument
   * @param nodeData updated data
   */
  public updateChildNode(nodeData) {
    //add original object to change history
    this.changeHistory.push(JSON.stringify(this.inputHolder));

    let stepId = nodeData._id;
    //find the edited node
    for (const key in this.inputHolder.steps) {
      if (this.inputHolder.steps[key]._id == stepId) {
        //set the value of the changed step to the modified data
        this.inputHolder.steps[key] = { ...nodeData };
        //init the document
        this.initObject(this.inputHolder);
        return;
      }
    }
  }

  public toJson() {}

  public static exportModeler() {}

  /**
   * @description gets the node id and extracts the initial 4 letters to determine if caller is a step or a node
   * @param stepId calling node id
   * @returns {string} caller type
   */
  public static getNodeType(stepId): string {
    if (stepId.includes("ANS_")) {
      return "answer";
    }

    return "step";
  }

  /**
   * @description gets data of a step based on id and type
   * @param stepId string of the calling node's id
   * @param isAnswer is the caller an answer
   * @param parentId if caller has a parent_id property
   * @returns {Object} returns the object found using it's id and type
   */
  public getStepData(stepId: string, isAnswer: boolean = false, parentId: string = null): {} {
    //check the type of the node
    if (isAnswer) {
      for (const key in this.inputHolder.steps) {
        if (this.inputHolder.steps[key]._id == parentId) {
          return this.inputHolder.steps[key];
        }
      }
    }

    //loop over the steps to find sand return the document
    for (const key in this.inputHolder.steps) {
      if (this.inputHolder.steps[key]._id == stepId) {
        return this.inputHolder.steps[key];
      }
    }
    return null;
  }

  private removeChildren() {
    let input = this.inputHolder;
    if (input.hasOwnProperty("children")) {
      delete input.children;
    }

    if (input.hasOwnProperty("steps") && Object.keys(input.steps).length > 0) {
      for (const key in input.steps) {
        if (input.steps[key].hasOwnProperty("children")) {
          delete input.steps[key].children;
        }
        if (input.steps[key].hasOwnProperty("answers")) {
          let ans = [];
          input.steps[key].answers.forEach((el) => {
            if (el.hasOwnProperty("children")) {
              delete el.children;
            }
            ans.push(el);
          });
          input.steps[key].answers = ans;
        }
      }
    }

    return input;
  }

  public getData() {
    let cleanData = this.removeChildren();
    return cleanData;
  }

  hasEntryStep(): boolean {
    if (this.inputHolder.entry > 0) {
      return true;
    }
    return false;
  }

  hasChangeHistory(): boolean {
    if (this.changeHistory.length > 0) {
      return true;
    }

    return false;
  }

  undoLastChange() {
    let latest = this.changeHistory.pop();
    this.inputHolder = JSON.parse(latest);
    this.initObject(this.inputHolder);
  }

  public updateParentNode(data) {
    console.log(data);
    this.inputHolder = { ...data };
    this.initObject(this.inputHolder);
  }
}

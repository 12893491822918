<form #personForm="ngForm" *ngIf="data">
    <div class="col-xl-12">
        <h1 mat-dialog-title>{{data.title}}</h1>
        <div mat-dialog-content>
            <div>
                <mat-card>
                    <mat-card-content>
                        <figure class="card-profile-image">
                            <div class="imageLayer" (click)="fileselect.click()">
                                <mat-icon class="image-icon">cloud_upload</mat-icon>
                            </div>
                            <img [src]="pictureLink" (click)="fileselect.click()"
                                class="responsive-img">
                            <input [hidden]="true" type="file" name="image" #fileselect
                                (change)="onBrowseFiles($event.target, 'logo')" />
                        </figure>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
        <div mat-dialog-actions>
            <button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
            <button *ngIf="data.data._id" mat-raised-button (click)="fileselect.click()"
                [disabled]="loading">{{'Upload' | translate}}</button>
            <button *ngIf="data.data._id && data.targetDeleteApi" mat-raised-button (click)="deleteFile(data.targetDeleteApi)"
                [disabled]="loading">{{'Clear' | translate}}</button>
        </div>
        <br />
    </div>
</form>

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ModelerData } from "src/app/model/Modeler-interface";
import { NgForm } from "@angular/forms";
import { DialogData } from "src/app/shared";

@Component({
  selector: "app-root-modeler-edit-dialog",
  templateUrl: "./root-modeler-edit-dialog.component.html",
  styleUrls: ["./root-modeler-edit-dialog.component.scss"],
})
export class RootModelerEditDialogComponent implements OnInit {
  //TODO: get from service
  charactersList = [
    { name: "john", gender: "male" },
    { name: "jane", gender: "female" },
  ];

  //TODO: get from service
  openingActsList = ["OpAct1", "OpAct2", "OpAct3", "OpAct4"];

  constructor(
    private dialogRef: MatDialogRef<RootModelerEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData & Partial<ModelerData>
  ) {}

  ngOnInit(): void {
    console.log(this.data);
  }

  onFormSubmit(form: NgForm, isCanceled = false) {
    if (isCanceled) {
      this.dialogRef.close(null);
      return;
    }

    if (form.invalid) return;

    let modeler: Partial<ModelerData> = {};

    modeler.name = form.controls.name.value;
    modeler.description = form.controls.description.value;
    modeler.characters = form.controls.characters.value;
    modeler.opening_acts = form.controls.opening_acts.value;
    //TODO: add uploads function that returns bg url after upload
    modeler.main_bg_url = form.controls.main_bg_url.value;
    modeler.number_of_steps = this.data.number_of_steps;
    modeler.creator = this.data.creator;
    modeler.entry = this.data.entry;
    modeler.steps = this.data.steps || {};
    modeler._id = this.data._id;

    this.dialogRef.close(modeler);
  }
}

<div class="row cc-options-sub-row cc_desc_opt">
 <!-- <span class="cc-sub-question-no">{{getLevelIndex()}}.{{getAlphaLetter(currentIndex)}}</span> -->
 <mat-form-field>
   <span matPrefix>{{getLevelIndex()}}.{{getAlphaLetter(currentIndex)}}</span>
   <input [style.display]="'none'" matInput name="answertype" [(ngModel)]="subOption.controlType">
   <mat-label>Answer Type</mat-label>
   <mat-select ng-model="answertype" (selectionChange)="subOption.controlType = $event.value" [value]="subOption.controlType">
     <mat-option value="text">Text</mat-option>
     <mat-option value="text_na">Text with Unknown</mat-option>
     <mat-option value="text_nil">Text with N/A</mat-option>
     <mat-option value="textarea">Text Area </mat-option>
     <mat-option value="textarea_na">Text Area with Unknown</mat-option>
     <mat-option value="textarea_nil">Text Area with N/A</mat-option>
     <mat-option value="date">Date</mat-option>
     <mat-option value="date_na">Date with Unknown</mat-option>
     <mat-option value="date_nil">Date with N/A</mat-option>
     <mat-option value="date_approximate">Date with approximate</mat-option>
     <mat-option value="datetime-local">Date and Time</mat-option>
     <mat-option value="datetime-local_na">Date and Time with Unknown</mat-option>
     <mat-option value="datetime-local_nil">Date and Time with N/A</mat-option>
     <mat-option value="datetime-local_approximate">Date and Time with approximate</mat-option>
     <mat-option value="time">Time Only</mat-option>
     <mat-option value="time_na">Time Only with Unknown</mat-option>
     <mat-option value="time_nil">Time Only with N/A</mat-option>
     <mat-option value="time_approximate">Time Only with approximate</mat-option>
     <mat-option value="number">Number</mat-option>
     <mat-option value="number_na">Number with Unknown</mat-option>
     <mat-option value="number_nil">Number with N/A</mat-option>
   </mat-select>
   <button mat-icon-button matSuffix (click)="deleteSubLevel($event)" [attr.aria-label]="'Remove option'" >
     <mat-icon>close</mat-icon>
   </button>
 </mat-form-field>

 <mat-form-field>
   <textarea matInput
       maxlength="1000"
       [(ngModel)]="subOption.questionText"
       cdkTextareaAutosize
       cdkAutosizeMinRows="2"
       cdkAutosizeMaxRows="5" (change)="onChange($event)">
   </textarea>
 </mat-form-field>
</div>

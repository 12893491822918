<!-- TODO: try shaping new nodes by class name + show option on hover -->
<main class="container" #mainContainer style="background-color: white !important">
  <ngx-graph
    class="chart-container"
    [view]="[800, 550]"
    [links]="[
      {
        id: 'a',
        source: 'first',
        target: 'second',
        label: 'is parent of'
      },
      {
        id: 'b',
        source: 'first',
        target: 'c1',
        label: 'custom label'
      },
      {
        id: 'd',
        source: 'first',
        target: 'c2',
        label: 'custom label'
      },
      {
        id: 'e',
        source: 'c1',
        target: 'd',
        label: 'first link'
      },
      {
        id: 'f',
        source: 'c1',
        target: 'd',
        label: 'second link'
      }
    ]"
    [nodes]="[
      {
        id: 'first',
        label: 'A'
      },
      {
        id: 'second',
        label: 'B'
      },
      {
        id: 'c1',
        label: 'C1'
      },
      {
        id: 'c2',
        label: 'C2'
      },
      {
        id: 'd',
        label: 'D'
      }
    ]"
    [clusters]="[
      {
        id: 'third',
        label: 'Cluster node',
        childNodeIds: ['c1', 'c2']
      }
    ]"
    layout="dagreCluster"
  >
    <ng-template #defsTemplate>
      <svg>
        <marker
          id="arrow"
          viewBox="0 -5 10 10"
          refX="8"
          refY="0"
          markerWidth="4"
          markerHeight="4"
          orient="auto"
        >
          <path d="M0,-5L10,0L0,5" class="arrow-head" />
        </marker>
      </svg>
    </ng-template>

    <ng-template #clusterTemplate let-cluster>
      <svg>
        <g
          class="node cluster"
          ngx-tooltip
          [tooltipPlacement]="'top'"
          [tooltipType]="'tooltip'"
          [tooltipTitle]="cluster.label"
        >
          <rect
            rx="5"
            ry="5"
            [attr.width]="cluster.dimension.width"
            [attr.height]="cluster.dimension.height"
            [attr.fill]="cluster.data.color"
          />
        </g>
      </svg>
    </ng-template>

    <ng-template #nodeTemplate let-node>
      <svg>
        <g
          class="node"
          ngx-tooltip
          [tooltipPlacement]="'top'"
          [tooltipType]="'tooltip'"
          [tooltipTitle]="node.label"
        >
          <rect
            [attr.width]="node.dimension.width"
            [attr.height]="node.dimension.height"
            [attr.fill]="node.data.color"
          />
          <text alignment-baseline="central" [attr.x]="10" [attr.y]="node.dimension.height / 2">
            {{ node.label }}
          </text>
        </g>
      </svg>
    </ng-template>

    <ng-template #linkTemplate let-link>
      <svg>
        <g class="edge">
          <path class="line" stroke-width="2" marker-end="url(#arrow)"></path>
          <text class="edge-label" text-anchor="middle">
            <textPath
              class="text-path"
              [attr.href]="'#' + link.id"
              [style.dominant-baseline]="link.dominantBaseline"
              startOffset="50%"
            >
              {{ link.label }}
            </textPath>
          </text>
        </g>
      </svg>
    </ng-template>
  </ngx-graph>
</main>

<div class="btn-group">
  <button mat-raised-button color="primary" (click)="exitEditor()" class="btn">Cancel</button>
  <button
    mat-raised-button
    color="primary"
    (click)="undoChanges()"
    [disabled]="hasChanges == false"
    class="btn"
  >
    Undo
  </button>
  <button
    [disabled]="savedLocally"
    mat-raised-button
    color="primary"
    (click)="saveChangesLocally()"
    class="btn"
  >
    Save
  </button>

  <button
    [disabled]="!dataModified"
    mat-raised-button
    color="primary"
    (click)="saveAndClose()"
    class="btn"
  >
    Save & Exit
  </button>
</div>
<!-- FULL SCREEN BTN -->
<button
  (click)="toggleFullScreenMode()"
  class="fullscreen-toggle-btn"
  color="primary"
  mat-mini-fab
  aria-label="toggle fullscreen mode"
>
  <mat-icon *ngIf="isFullScreen == false">fullscreen</mat-icon>
  <mat-icon *ngIf="isFullScreen">fullscreen_exit</mat-icon>
</button>
<!-- CONTEXT MENU -->
<div class="context-menu__container hidden" #contextMenu>
  <ul class="context-menu__list">
    <li (click)="viewNodeData()" class="context-menu__item">
      <img src="assets/images/view-context.svg" alt="icon" class="context-menu__item__icon" />
      View
    </li>
    <li (click)="openEditDialog()" class="context-menu__item">
      <img src="assets/images/edit-context.svg" alt="icon" class="context-menu__item__icon" />
      Edit
    </li>
    <li (click)="openNewDialog()" class="context-menu__item">
      <img src="assets/images/plus-context.svg" alt="icon" class="context-menu__item__icon" />
      Add child step
    </li>
    <li (click)="openDeleteDialog()" class="context-menu__item">
      <img src="assets/images/delete-context.svg" alt="icon" class="context-menu__item__icon" />
      Delete
    </li>
  </ul>
</div>

import { Component, OnInit, ViewChild, OnDestroy, ElementRef, Input, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { ModalGalleryDialogComponent } from '../custom-gallery-dialog/custom-gallery-dialog.component';
import { TilesListComponent } from '../tiles-list/tiles-list.component';
import { ModalPreviewDialogComponent } from '../custom-preview-dialog/custom-preview-dialog.component';
import { Utils } from '../../helpers/utils';
import { RequestService, LayoutUtilsService, PageScopeService, StoreService, LoaderService } from '../../services';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { BlockOrganizer, BlockComponent, BlockItem, GetBlocks } from '../../components/widgets/block-organizer';

class MyUserErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-tile-data',
  templateUrl: './tile-data.component.html',
  styleUrls: ['./tile-data.component.scss']
})
export class TileDataComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public originalTableSetting: any = undefined;
  public tableSetting: any = undefined;
  public errorMessage: string = '';
  public filterObject: any = undefined;
  public scopes: string[] = [];
  public defaultFields: string[] = ['name'];
  public fieldsObject: string[] = JSON.parse(JSON.stringify(this.defaultFields));

  public filterStored = { '$and': [] };

  public classId: string = undefined;
  public lectureId: string = undefined;

  public canExport: boolean = true;
  public canFilter: boolean = true;
  public loading: boolean = false;
  public tableName: string = 'tile';
  public dataType: string = 'tile';
  public dataTypeDisplay: string = this.translate.instant('Library');
  public UIDisplay: string = this.translate.instant('Content');
  public filterType: string;
  public fieldsToHide: string[] = [];
  public targetName: string = 'tiles';

  public selectedOrganization: string = "-1";
  public selectedLanguage: string = "en";
  public organizations: any[] = [];
  public blocks: any[] = [];
  public originalBlocksData: any[] = [];
  public tileIdsDelete: string[] = [];
  public tileIdsUpdate: Object = {};
  public tileBlocks: any[] = [];
  public profileDatas: any[] = [];
  public tileCategories: any[] = [];
  public widgetCategories: any[] = [];
  public languageList: any[] = [];
  public tileThemes: any[] = [];
  public widgetRights: any[] = [];
  public rtl: boolean = false;
  public defaultThemeId: string = "-1";
  public orgTileCategory: string = "-1";
  public template: string = "-1";
  public startWrapper: boolean = false;
  public esMatcher = new MyUserErrorStateMatcher();

  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public selectedDataEmpty: any = {
    title: '',
    notes: '',
    language: 'en',
    category: '-1',
    type: 'content',
    template: this.defaultThemeId,
    rtl: false,
    requiresLogin: false,
    enableZoom: false,
    showAsTabs: false,
    blocksData: [],
    blocks: [],
    organizationId: [],
    createdOrg: undefined,
    isRoleBased: false,
    userName: '',
    lastUpdatedUser: '',
    art: '/assets/img/tile_default.jpg'
  };
  public selectedDataKeysType: any = {
    title: { name: 'title', displayName: 'title', type: 'string', valueType: 'string', check: true },
    // notes: {name: 'notes', displayName: 'Notes',type: 'string', valueType: 'string', check: true},
    // language: {name: 'language', displayName: 'Language',type: 'list', valueType: 'id', check: true},
    category: { name: 'category', displayName: 'Category', type: 'list', valueType: 'id', check: true },
    // type: {name: 'type', displayName: 'Type',type: 'string', valueType: 'string', check: true},
    // template: {name: 'template', displayName: 'Template',type: 'list', valueType: 'id', check: true},
    rtl: { name: 'rtl', displayName: 'Rtl', type: 'boolean', valueType: 'boolean', check: true },
    // requiresLogin: {name: 'requiresLogin', displayName: 'Requires Login',type: 'boolean', valueType: 'boolean', check: true},
    // enableZoom: {name: 'enableZoom', displayName: 'Enable Zoom',type: 'boolean', valueType: 'boolean', check: true},
    // showAsTabs: {name: 'showAsTabs', displayName: 'show As Tabs',type: 'boolean', valueType: 'boolean', check: true},
    blocksData: { name: 'blocksData', displayName: 'Blocks Data', type: 'array', valueType: 'arrayblocks', check: true },
    // blocks: {name: 'blocks', displayName: 'Blocks',type: 'array', valueType: 'array', check: true},
    // organizationId: {name: 'organizationId', displayName: 'Organization Id', type: 'array', valueType: 'array', check: true},
    // createdOrg: {name: 'createdOrg', displayName: 'Created Org', type: 'id', valueType: 'id', check: true},
    art: { name: 'art', displayName: 'Art', type: 'image', valueType: 'string', check: true },
    // userName: {name: 'userName', displayName: 'User Name', type: 'string', valueType: 'string', check: true},
    // lastUpdatedUser: {name: 'lastUpdatedUser', displayName: 'LastUpdatedUser', type: 'string', valueType: 'string', check: true},
  };
  public selectedDataKeysTypeList = Object.keys(this.selectedDataKeysType);

  public selectedOriginalData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));
  public selectedData: any = JSON.parse(JSON.stringify(this.selectedDataEmpty));

  @Input() isDialog: boolean = false;
  @Output() onSaveReturn = new EventEmitter<any>();
  public searchVal: string = '';
  @ViewChild(MatPaginator) paginator: MatPaginator;
  // orderByList = [{field: 'firstName',order: 'asc'}];

  // @ViewChild('customMetadataTable') custom_metadata_table: CustomMetadataTableComponent;
  @ViewChild('tileList') tile_list: TilesListComponent;
  constructor(public store: StoreService, private requestService: RequestService, private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageScopeService: PageScopeService,
    private layoutUtilsService: LayoutUtilsService,
    private utils: Utils,
    public dialog: MatDialog, private loaderService: LoaderService, private translate: TranslateService) { }

  ngOnInit() {
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          this.selectedOrganization = this.requestService.orgId;
          this.selectedDataEmpty['organizationId'] = [this.selectedOrganization];
          this.selectedDataEmpty['createdOrg'] = this.selectedOrganization;
          this.selectedData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
          // this.getOrgProfileDatas();
          this.getOrganizations();
          this.getLanguages();
          // this.getThemes(); // we don;t need t here
          this.setWidgetRights();
        }
      })
    );
    // this.subscriptions.push(
    //   this.pageScopeService.menuSelectedScope.subscribe( (scopes) => {
    //       this.scopes = scopes
    //     }
    //   )
    // );

  }

  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  toggleClick(action) {
    if (action === 'new') {
      this.newData();
    } else if (action === 'delete') {
      this.tile_list.delete(undefined, this.selectedData['_id']);
    } else if (action === 'duplicate') {
      this.duplicate();
    } else if (action === 'save') {
      this.save();
    } else if (action === 'preview') {
      this.preview();
    } else if (action === 'background') {
      // this.background();
    } else if (action === 'icon') {
      this.icon();
    }
  }
  tileActionReturn(actions) {
    //console.log('tileActionReturn', actions);
    if (actions.action === 'widgetTileReset') {
      this.widgetTileReset(actions.val1, actions.val1);
    }
    this.widgetTileReset(true, true);
  }
  widgetTileReset(isNew?: boolean, isBlocks?: boolean, isUpdate?: boolean) {
    if (isNew) {
      this.selectedData = JSON.parse(JSON.stringify(this.selectedDataEmpty));
      this.selectedOriginalData = JSON.parse(JSON.stringify(this.selectedData));
      this.originalBlocksData = [];
    }

    if (isBlocks) {
      this.blocks = [];
    }
    if (!isUpdate) {
      this.selectedLanguage = "en";
      this.startWrapper = false;
    }

    // this.tileIdsDelete = [];
  };
  dataChanged() {
    for (let ky of this.selectedDataKeysTypeList) {
      if (this.selectedDataKeysType[ky].check === true) {
        let val1 = this.selectedOriginalData[ky];
        let val2 = this.selectedData[ky];
        if (this.selectedDataKeysType[ky].valueType === 'array') {
          if (val1.length !== val2.length) {
            return true;
          }
        } else if (this.selectedDataKeysType[ky].valueType === 'arrayblocks') {
          for (let blkData of this.blocks) {
            if (blkData.hasOwnProperty("_id")) {
              let newBlockData = Object.assign({}, this.getBlockData(blkData));
              let oldBlockData = Object.assign({}, this.getBlockData(blkData["existingData"]));
              let result = this.utils.compareObj(newBlockData, oldBlockData);
              if (!result) {
                return true;
              }
            } else {
              return true;
            }
          }
        } else {
          if (val1 !== val2) {
            return true;
          }
        }
      }
    }
    return false;
  }
  getBlockData(blockData) {
    let blobObj = {
      _id: blockData["_id"],
      data: blockData["data"],
      type: blockData["type"],
      activate: blockData["activate"],
      version: blockData["version"],
      isForm: blockData["isForm"]
    };
    return blobObj;
  }
  newData() {
    if (this.dataChanged()) {
      this.newTile('Would you like to save your previous work?', 'Save', 'Discard', (value) => {
        if (value === 'Save') {
          this.tileSave("", true, false, false, this.selectedData);
        } else if (value === 'Discard') {
          this.widgetTileReset(true, true);
        }
      });
    } else {
      this.widgetTileReset(true, true);
    }
  }
  duplicate() {
    let currTileExists: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
    if (!this.utils.isEmptyObject(currTileExists) && currTileExists.hasOwnProperty("_id")) {
      if (this.dataChanged()) {
        this.newTile('Would you like to save your work and duplicate?', 'Yes', 'No', (value) => {
          if (value === 'Yes') {
            this.tileSave("", true, true);
          } else if (value === 'No') {
            this.tileSave("", false, true);
          }
        });
      } else {
        this.tileSave("", false, true);
      }
    }
  }
  selectItemCheck(data) {
    let currTileExists: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
    if (!this.utils.isEmptyObject(currTileExists)) { //&& currTileExists.hasOwnProperty("_id")
      if (this.dataChanged()) {
        this.newTile('Would you like to save your work and select?', 'Yes', 'No', (value) => {
          if (value === 'Yes') {
            this.tileSave("", true, false, false, undefined, false, undefined, true, data);
          } else if (value === 'No') {
            this.selectItem(data);
          }
        });
      } else {
        this.selectItem(data);
      }
    } else {
      this.selectItem(data);
    }
  }
  selectItem(data, langCode: string = 'en') {
    this.selectedData = JSON.parse(JSON.stringify(data));
    this.selectedOriginalData = JSON.parse(JSON.stringify(data));
    this.originalBlocksData = JSON.parse(JSON.stringify(this.selectedData.blocksData));
    if (this.selectedData['organizationId'].indexOf(this.selectedOrganization) === -1) {
      this.selectedData['organizationId'] = [this.selectedData['organizationId']];
    }
    this.blocks = [];
    this.selectedData.blocks = []; // added this so i don't alter the blocks
    if (this.selectedData.blocksData.length > 0) {
      for (let i = 0; i < this.selectedData.blocksData.length; i++) {
        let currentBlock: any = this.selectedData.blocksData[i];
        let type: string = this.selectedData.blocksData[i].hasOwnProperty("type") ? this.selectedData.blocksData[i].type : "";
        this.loadWidgets("", type, currentBlock, "");
      }
    }
    this.selectedLanguage = langCode;
    //console.log('selectedData', this.selectedData);
    // console.log('originalBlocksData', this.originalBlocksData);
    // console.log('blocks', this.blocks);
    // Prepare Data
  }


  public removeWidget(idx: any) {
    this.blocks.splice(idx, 1);
  }
  public deleteWidget(idx: any) {
    this.blocks[idx]['activate'] = false;
  }
  public unDeleteWidget(idx: any) {
    this.blocks[idx]['activate'] = true;
  }
  public isValid() {
    // no need to valide here we already have checks for the name in tileSave maybe in the new studio
    return true;
  }
  public save() {
    if (this.isValid()) {
      this.tileSave("", true, false, false, this.selectedData);
    } else {
      this.layoutUtilsService.showNotification('Fill all required fields', 'Dismiss');
    }
  }
  public preview() {
    const dialogRef = this.dialog.open(ModalPreviewDialogComponent, {
      disableClose: false,
      panelClass: 'myPreviewClass',
      data: {
        title: this.translate.instant('Preview'),
        data: this.requestService.getTileLink(undefined, this.selectedData._id, true)
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        // nothing to do
      }
    });
  }
  // public background() {
  //   const dialogRef = this.dialog.open(ModalBackgroundDialogComponent, {
  //       disableClose: false,
  //       panelClass: 'myBackroundClass',
  //       data: {
  //         title: 'TILE BACKGROUNDS',
  //         data: this.selectedData,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       // nothing to do
  //       this.selectedData.tileBackgroundPortrait = result.tileBackgroundPortrait;
  //       this.selectedData.tileBackgroundLandscape = result.tileBackgroundLandscape;
  //     }
  //   });
  // }
  public icon() {
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      panelClass: 'myBackroundClass',
      autoFocus: false,
      data: {
        title: this.translate.instant('Image Library'),
        data: this.selectedData,
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.selectedData.art = result;
      }
    });
  }
  public checkStartWrapper(type: string) {
    if (type === "startwrapper") {
      this.startWrapper = true;
    }

    return true;
  };
  newTile(message: any, yesBtn: any, noBtn: any, cb: any) {
    this.utils.iAlertConfirm("confirm", "Confirm", message, yesBtn, noBtn, (res) => {
      cb(res["value"]);
    });
  };
  languageChange(langCode: string, saved: boolean = false) {
    if (!saved) {
      let isNew: boolean = this.selectedData.hasOwnProperty("_id") ? false : true;
      if (isNew) {
        this.newTile('You must save the changes to update in other languages', 'Save', 'Discard', (value) => {
          if (value === 'Save') {
            this.tileSave("", false, false, false, {}, true, langCode);
          } else {
            this.selectedLanguage = undefined;
            setTimeout(() => {
              this.selectedLanguage = 'en';
            }, 300);
          }
        });
      } else {
        this.langReset(langCode);
      }
    } else {
      this.loaderService.display(false);
      this.langReset(langCode);
    }
  };

  langReset(langCode: string, isSpinner?: boolean) {
    this.selectedLanguage = langCode;

    // Reset the tile
    if (this.selectedData.hasOwnProperty(langCode)) {
      this.selectedData['title'] = this.selectedData[langCode]['title'];
      this.selectedData['notes'] = this.selectedData[langCode]['notes'];
      this.selectedData['rtl'] = this.selectedData[langCode]['rtl'];
    } else {
      this.selectedData['title'] = '';
      this.selectedData['notes'] = '';
      this.selectedData['rtl'] = false;
      this.selectedData[langCode] = { title: '', notes: '', rtl: false };
    }

    this.blocks = [];
    if (this.selectedData.blocksData.length > 0) {
      for (let i = 0; i < this.selectedData.blocksData.length; i++) {
        let currentBlock = this.selectedData.blocksData[i];
        let type = this.selectedData.blocksData[i].hasOwnProperty("type") ? this.selectedData.blocksData[i].type : "";
        this.loadWidgets("", type, currentBlock, "");
      }
    }

    if (isSpinner) {
      this.loaderService.display(true);
    }
  };
  public loadWidgets(e, type, blockData, blkName) {
    if (!this.utils.isNullOrEmpty(e)) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    let viewName = '';
    var result = !this.utils.isNullOrEmpty(blkName) && this.blocks.length > 0 ? this.manageWidgets(type, blkName) : this.checkStartWrapper(type);

    if (result) {
      if (type === "text") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "textView";
      }

      if (type === "linkdescription") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "linkDescriptionView";
      }

      if (type === "video") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "videoView";
      }

      if (type === "picture") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "pictureView";
      }

      if (type === "disqus") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "disqusView";
      }

      if (type === "feed") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "feedView";
      }

      if (type === "calendar") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "calendarView";
      }

      if (type === "share") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "shareView";
      }

      if (type === "patients") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "patientsView";
      }

      if (type === "inquiry") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "inquiryView";
      }

      if (type === "survey") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization));
        viewName = "surveyView";
      }

      if (type === "questionnaire") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization));
        viewName = "questionnaireView";
      }

      if (type === "startwrapper") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "startWrapperView";
      }

      if (type === "title") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "formTitleView";
      }

      if (type === "questions") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], this.widgetCategories, this.utils, this.selectedOrganization));
        viewName = "questionsView";
      }

      if (type === "attendance") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "attendanceView";
      }

      if (type === "confirmation") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "confirmationView";
      }

      if (type === "password") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "passwordView";
      }

      if (type === "next") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));

        viewName = "nextView";
      }

      if (type === "formphoto") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "formPhotoView";
      }

      if (type === "formdocument") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "formDocumentView";
      }

      if (type === "painlevel") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "painLevelView";
      }

      if (type === "drawtool") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "drawToolView";
      }

      if (type === "physician") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "physicianView";
      }

      if (type === "endwrapper") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "endWrapperView";
      }

      if (type === "fill") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "fillView";
      }

      if (type === "notes") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "notesView";
      }

      if (type === "buttons") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "buttonsView";
      }

      if (type === "contactus") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "contactusView";
      }

      if (type === "placefull") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));

        viewName = "placefullView";
      }

      if (type === "addtocart") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "addToCartView";
      }

      if (type === "cart") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "cartView";
      }

      if (type === "blanksform") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "blanksFormView";
      }

      if (type === "exclusiveurl") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "exclusiveUrlView";
      }

      if (type === "fileupload") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "fileUploadView";
      }

      if (type === "pushpay") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "pushPayView";
      }

      if (type === "threedcart") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "threedCartView";
      }

      if (type === "blogs") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "blogsView";
      }

      if (type === "chat") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, [], [], this.utils, this.selectedOrganization));
        viewName = "chatView";
      }

      if (type === "account") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, this.profileDatas, [], this.utils, this.selectedOrganization));
        viewName = "accountView";
      }

      if (type === "profile") {
        this.blocks.push(new BlockOrganizer(blockData, type, this.selectedLanguage, this.profileDatas, [], this.utils, this.selectedOrganization));
        viewName = "profileView";
      }
      // console.log('loadWidgets', this.blocks);
    }
  }
  public moveSubData(isUp, idx) {
    let blocks = JSON.parse(JSON.stringify(this.blocks));
    let newIdx = idx;
    let currentItem = blocks.splice(idx, 1);
    if (isUp) {
      newIdx = newIdx - 1;
    }
    blocks.splice(newIdx, 0, currentItem[0]);
    this.blocks = JSON.parse(JSON.stringify(blocks));
  }
  public actionData(idx, dataObject) {
    // console.log('clickWidget', idx, dataObject);
    if (dataObject.action === 'imageLibrary') {
      this.galleryLibrary(idx, dataObject);
    } else if (dataObject.action === 'videoLibrary') {
      // this.videoLibrary(idx, dataObject);
    } else if (dataObject.action === 'videoThumb') {
      // add thumb upload
    } else {
      //console.log('block change', this.blocks[idx]);
    }
    // we ,ight need to reset the block this.blocks[idx]=dataObject.block
  }

  public galleryLibrary(idx, dataObject) {
    const dialogRef = this.dialog.open(ModalGalleryDialogComponent, {
      width: '1600px',
      disableClose: false,
      autoFocus: false,
      data: {
        title: this.translate.instant('Upload image'),
        data: [],
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let blocks = JSON.parse(JSON.stringify(this.blocks));
        blocks[idx]['data'][dataObject['block']['target']] = result;
        this.blocks = JSON.parse(JSON.stringify(blocks));
      }
    });
  }
  // public videoLibrary(idx, dataObject) {
  //   const dialogRef = this.dialog.open(ModalVideosDialogComponent, {
  //       width: '1600px',
  //       disableClose: false,
  //       panelClass: 'myBackroundClass',
  //       data: {
  //         title: 'Video Library',
  //         data: '',
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       if(result.hasOwnProperty('vimeoId')){
  //         let blocks = JSON.parse(JSON.stringify(this.blocks));
  //         blocks[idx]['data'][dataObject['block']['target']] = environment.vimeoUrl + result.vimeoId;
  //         this.blocks = JSON.parse(JSON.stringify(blocks));
  //       }
  //     }
  //   });
  // }
  /*   Get Organization profile datas    */
  getOrgProfileDatas() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.getDataLByOrg('tileblock/getprofile', (data, error) => {
        this.loading = false;
        if (error) {
          //console.log(error);
        }
        if (data) {
          this.profileDatas = data.results;
        }
      }, true);
    }
  };
  getOrganizations() {
    this.requestService.getDataL('organization', (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        this.organizations = data.results;
      }
    }, true);
  };
  getLanguages() {
    this.requestService.getDataL('language', (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        this.languageList = data.results;
      }
    }, true);
  };

  getThemes() {
    this.requestService.getDataL('template', (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        this.tileThemes = data.results;
        this.getWhiteTheme();
      }
    }, true);
  };

  getWhiteTheme() {
    this.defaultThemeId = "-1";

    if (this.utils.isArray(this.tileThemes) && this.tileThemes.length > 0) {
      for (let i = 0; i < this.tileThemes.length; i++) {
        var themeObj = this.tileThemes[i];

        if (themeObj.hasOwnProperty("name") && !this.utils.isNullOrEmpty(themeObj["name"]) && this.utils.trim(themeObj["name"].toLowerCase()) === "white") {
          this.defaultThemeId = themeObj["_id"];
          break;
        }
      }
    }

    this.template = this.defaultThemeId;
  };
  setWidgetRights() {
    this.requestService.getSingleData('organization/package', this.selectedOrganization, (data, error) => {
      if (error) {
        //console.log(error);
      }
      if (data) {
        if (data && data.length > 0) {
          var packageRights = data[0].hasOwnProperty('rights') && data[0].rights.length > 0 ? data[0].rights : [];

          if (this.utils.isArray(packageRights) && packageRights.length > 0) {
            for (let i = 0; i < packageRights.length; i++) {
              this.widgetRights.push(packageRights[i].toLowerCase().replace(/ /g, ''))
            }
          }
        }
      }
    });
  };
  manageWidgets(currentWidget: string, widgetName: string) {
    if (this.widgetRights.length > 0) {
      var widgetBlockName = widgetName.toLowerCase().replace(/\s+/g, '');
      var rightIndex = this.widgetRights.indexOf(widgetBlockName);

      if (rightIndex == -1) {
        var orgIndex = this.organizations.map(b => {
          return b['_id'];
        }).indexOf(this.selectedOrganization);

        var orgName = orgIndex !== -1 ? this.organizations[orgIndex]["name"] : "";
        this.utils.iAlert('info', this.translate.instant('Information'), orgName + ' does not have access to this widget');
        return false;
      }
    }

    var formGroupCheck = 0;
    var formOneCheck = 0;
    var exclusiveCheck = 0;
    var displayCheck = 0;
    var forms = ['title', 'account', 'attendance', 'survey', 'questionnaire', 'questions', 'confirmation', 'inquiry', 'profile', 'password', 'next', 'user', 'formphoto', 'mobilevideo', 'physician'];
    var formsOne = ['fill', 'notes', 'buttons', 'contactus', 'placefull', 'addtocart', 'cart', 'blanksform'];
    var displayWigets = ['text', 'linkdescription', 'video', 'image', 'url', 'picture', 'disqus', 'feed', 'calendar'];
    var exclusiveWidgets = ['exclusiveurl', 'fileupload', 'pushpay', 'threedcart', 'blogs', 'chat'];
    var formIndex = forms.indexOf(currentWidget);
    var formsOneIndex = formsOne.indexOf(currentWidget);
    var displayIndex = displayWigets.indexOf(currentWidget);
    var exclusiveIndex = exclusiveWidgets.indexOf(currentWidget);
    var blogsChk = false;

    if (currentWidget == 'startwrapper') {
      if (!this.startWrapper) {
        this.startWrapper = true

        return true;
      } else {
        this.utils.iAlert("info", "Information", "Start wrapper already added");
      }

      return false;
    }

    var currentBlock = this.blocks[this.blocks.length - 1];
    var lastBlock = currentBlock;
    var lastType = lastBlock["type"];

    if (!this.startWrapper && currentWidget === "confirmation") {
      if (lastType === "survey" || lastType === "questionnaire") {
        var isSingle = false;

        if (lastType === "survey") {
          isSingle = lastBlock["data"]["multiple"] === "false" ? true : false;
        }

        if (lastType === "questionnaire") {
          isSingle = lastBlock["data"]["questionType"] === "single" ? true : false;
        }

        if (isSingle) {
          if (lastType === "survey") {
            for (let i = 0; i < lastBlock["data"]["questions"].length; i++) {
              lastBlock["data"]["confirmation"].push("");
            }
          }

          if (lastType === "questionnaire") {
            for (let i = 0; i < lastBlock["data"]["options"].length; i++) {
              lastBlock["data"]["confirmation"].push("");
            }
          }
        }

        return false;
      }
    }

    if (currentWidget == "endwrapper" && lastType == "startwrapper") {
      this.utils.iAlert("info", "Information", "No forms inside this group. Add form to close this group");

      return false;
    }

    if (currentWidget == "confirmation" && lastType == "next") {
      this.utils.iAlert("info", "Information", "Next Tile already added in this group!!!");
      return false;
    }

    if (currentWidget == "next" && lastType == "confirmation") {
      this.utils.iAlert("info", "Information", "Confirmation already added in this group!!!");
      return false;
    }

    var linkForm = this.blocks.filter(b => {
      return b["type"] === "title";
    });

    if (currentWidget === "next" && this.startWrapper && linkForm.length > 0) {
      var linkBlock = linkForm[0];

      if (linkBlock[0]["data"]["title"]) {
        this.utils.iAlert("info", "Information", "Next Widget cannot combine with linkable forms!!!");
        return false;
      }
    }

    if (currentWidget == 'endwrapper') {
      if (this.startWrapper) {
        this.startWrapper = false

        return true;
      } else {
        this.utils.iAlert("info", "Information", "No Start wrapper added!! Click start to end wrapper !!");
      }

      return false;
    }

    if (lastType === "next" && currentWidget == 'next') {
      this.utils.iAlert("info", "Information", "You cannot add Next widget after Next widget");

      return false;
    }

    if (lastType === "next" && lastBlock["data"]["type"] === "tile") {
      this.utils.iAlert("info", "Information", "You cannot add widget after Next widget");
      return false;
    }

    if (this.startWrapper && (currentWidget === "title" || currentWidget === "confirmation" || currentWidget === "next")) {
      var grpIdx = -1;
      var confirmIndex = -1;
      var nextIndex = -1;
      var nextWidgetIndex = -1;
      var titleIndex = -1;

      for (let i = this.blocks.length - 1; 0 <= i; i--) {
        if (this.blocks[i]["type"] === "title") {
          titleIndex = i;
        }

        if (this.blocks[i]["type"] === "confirmation") {
          confirmIndex = i;
        }

        if (this.blocks[i]["type"] === "next") {
          nextIndex = i;
        }

        if (this.blocks[i]["type"] === "startwrapper") {
          grpIdx = i;
          break;
        }
      }

      nextWidgetIndex = this.blocks.length - 1 > grpIdx ? grpIdx + 1 : -1;

      if (currentWidget == "next" && nextIndex !== -1) {
        this.utils.iAlert("info", this.translate.instant('Information'), "Next Tile already added in this group. Please end wrapper to close this group");
        return false;
      }

      if (currentWidget == "confirmation" && nextIndex !== -1) {
        this.utils.iAlert("info", this.translate.instant('Information'), "Confirmation already added in this group. Please end wrapper to close this group");
        return false;
      }

      if (lastType === "confirmation" || lastType === "next") {
        this.utils.iAlert("info", this.translate.instant('Information'), "Please end wrapper to close this group for adding this widget");

        return false;
      }

      if (nextWidgetIndex !== -1 && (currentWidget != "confirmation" && currentWidget != "next") && lastType != "startwrapper") {
        var nextType = this.blocks[nextWidgetIndex]["type"];

        if (nextType == "title" && titleIndex !== -1) {
          this.utils.iAlert("info", this.translate.instant('Information'), "One title is allowed inside the group");
        } else {
          this.utils.iAlert("info", this.translate.instant('Information'), "Title will be in the top inside the group");
        }

        return false;
      }
    }

    if (formsOneIndex !== -1 && this.startWrapper) {
      this.utils.iAlert("info", this.translate.instant('Information'), "A restricted widget cannot be combined with a form widget");
      return false;
    }

    if (displayIndex !== -1 && this.startWrapper) {
      this.utils.iAlert("info", this.translate.instant('Information'), "This widget cannot be used inside of a Form Wrapper");
      return false;
    }

    for (let i = 0; i < forms.length; i++) {
      var formIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(forms[i]);

      if (formIndx !== -1) {
        formGroupCheck++;
      }
    }

    for (let i = 0; i < formsOne.length; i++) {
      var formOneIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(formsOne[i]);

      if (formOneIndx !== -1) {
        formOneCheck++;
      }
    }

    for (let i = 0; i < exclusiveWidgets.length; i++) {
      var exclusiveWidgetsIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(exclusiveWidgets[i]);

      if (exclusiveWidgetsIndx !== -1) {
        exclusiveCheck++;
      }
    }

    for (let i = 0; i < displayWigets.length; i++) {
      var displayWigetsIndx = this.blocks.map(b => {
        return b['type'];
      }).indexOf(displayWigets[i]);

      if (displayWigetsIndx !== -1) {
        displayCheck++;

        if (displayWigets[i] === "blogs") {
          blogsChk = true;
        }
      }
    }

    if ((formGroupCheck > 0 && formsOneIndex !== -1) || (formsOneIndex !== -1 && formOneCheck > 0) || (formOneCheck > 0 && formIndex !== -1)) {
      if ((formGroupCheck > 0 && formsOneIndex !== -1)) {
        this.utils.iAlert("info", this.translate.instant('Information'), "A Restricted widget cannot be combined with a Form widget");
        return false;
      }

      if (formOneCheck > 0 && formIndex !== -1) {
        this.utils.iAlert("info", this.translate.instant('Information'), "A Form widget cannot be added in the same Tile with Restricted widgets");
        return false;
      }
    }

    if (exclusiveIndex !== -1 || exclusiveCheck > 0) {
      if ((formGroupCheck > 0 || formOneCheck > 0 || displayCheck > 0) || (formIndex !== -1 || formsOneIndex !== -1 || displayIndex !== -1)) {
        this.utils.iAlert("info", "Information", "An exclusive widget cannot be combined with other widgets");
        return false;
      }

      if (exclusiveCheck > 0) {
        this.utils.iAlert("info", this.translate.instant('Information'), "An exclusive widget cannot be added with same exclusive widgets");
        return false;
      }
    }

    if (blogsChk && currentWidget === "blogs") {
      this.utils.iAlert("info", this.translate.instant('Information'), "Blog already exists");
      return false;
    }

    if (this.startWrapper && currentWidget === 'profile') {
      var startIdx = -1;

      for (let i = this.blocks.length - 1; 0 <= i; i--) {
        if (this.blocks[i]["type"] === "startwrapper") {
          startIdx = i;
          break;
        }
      }

      if (startIdx !== -1) {
        var clearEnabled = this.blocks[startIdx]["data"]["refresh"];

        if (clearEnabled) {
          this.utils.iAlert("info", this.translate.instant('Information'), "Unable to add profile widget with clear fields");
          return false;
        }
      }
    }

    return true;
  };

  startEndWrapperCheck() {
    let currentBlocks: any = this.blocks;
    let wrapper: any = {
      "startWrapper": 0,
      "endWrapper": 0,
      "passed": 0,
      "failed": 0
    }

    for (let i = 0; i < currentBlocks.length; i++) {
      if (currentBlocks[i].hasOwnProperty("block") && currentBlocks[i].hasOwnProperty("type")) {
        var type = currentBlocks[i]["type"];

        if (type === "startwrapper") {
          wrapper["startWrapper"] = wrapper["startWrapper"] + 1;
        } else if (type === "endwrapper") {
          wrapper["endWrapper"] = wrapper["endWrapper"] + 1;
        }

        if (wrapper["startWrapper"] === 1 && wrapper["endWrapper"] == 1 && type === "endwrapper") {
          wrapper["startWrapper"] = 0;
          wrapper["endWrapper"] = 0;

          wrapper["passed"] = wrapper["passed"] + 1;
        }


        if ((wrapper["startWrapper"] > 1 || wrapper["endWrapper"] > 1) || (wrapper["startWrapper"] === 1 && wrapper["endWrapper"] == 1 && type === "startWrapper") || (i === currentBlocks.length - 1 && (wrapper["startWrapper"] === 1 || wrapper["endWrapper"] === 1))) {
          wrapper["failed"] = 1;
          break;
        }
      }
    }


    return wrapper["failed"] === 1 ? false : true;
  };
  assignExistsLanguage(langCode?: string) {
    if (langCode) {
      let currLang: string = this.selectedLanguage;
      this.selectedLanguage = langCode;

      setTimeout(() => {
        this.selectedLanguage = currLang;
      }, 500);
    }
  };
  tileSave(e: any, isUpdate?: boolean, isDuplicate?: boolean, updated?: boolean, newTileObj?: Object, langSave?: boolean, langCode?: string, selectData?: boolean, dataToSelect?: string) {
    if (!this.utils.isNullOrEmpty(e)) {
      e.preventDefault();
      e.stopPropagation();
    }
    this.loaderService.display(true);

    let updateId: string = "-1";
    let tileObj: any = Object.assign({}, this.selectedData);

    if (!isUpdate && isDuplicate) {
      this.selectItem(this.selectedOriginalData);
      tileObj = Object.assign({}, this.selectedData);
      if (updated) {
        updateId = tileObj["_id"];
      }

      tileObj["title"] = "Copy of " + tileObj["title"];
      delete tileObj["_id"];
    }

    if (this.utils.isNullOrEmpty(tileObj["title"])) {
      this.utils.iAlert('error', this.translate.instant('Information'), 'You must at least enter a Tile title');
      // this.assignExistsLanguage(langCode);
      this.loaderService.display(false);
      return false;
    }

    // if (tileObj["category"] === "-1") {
    //   this.utils.iAlert('error', 'Information', 'Please select a category for the Tile');
    //   this.assignExistsLanguage(langCode);
    //   this.loaderService.display(false);
    //   return false;
    // }

    // this.selectedData.blocksData = this.getBlocks(); // we are taking what is save and putting it here

    let isNewBlock: boolean = tileObj.hasOwnProperty("_id") ? false : true;
    let listOfnew = this.getBlocks(isNewBlock);
    tileObj.blocksData = listOfnew;
    this.checkTileOrgs(tileObj, (currTileObj: any, deleteId: any) => {
      if (this.selectedLanguage !== "en") {
        currTileObj[this.selectedLanguage] = {};
        currTileObj[this.selectedLanguage]["title"] = this.selectedData.title;
        currTileObj[this.selectedLanguage]["notes"] = this.selectedData.notes;
        currTileObj[this.selectedLanguage]["rtl"] = this.selectedData.rtl;

        currTileObj["title"] = this.selectedOriginalData.title;
        currTileObj["notes"] = this.selectedOriginalData.notes;
        currTileObj["rtl"] = this.selectedOriginalData.rtl;
      }
      this.saveTile(currTileObj, deleteId, isUpdate, isDuplicate, updateId, newTileObj, langSave, langCode, selectData, dataToSelect);
    });
  };
  getBlocks(isNewBlock) {
    let currentBlocks: any = this.blocks;
    let blckObj: any = new GetBlocks(currentBlocks, this.selectedLanguage, isNewBlock, this.utils);
    let blkDataObjs: any = blckObj.getBlockDatas();
    if (blkDataObjs["blocks"].length > 0) {
      return blkDataObjs["blocks"];
    } else {
      return [];
    }
  };
  checkTileOrgs(tile: any, cb: any) {
    let deleteId: string = "-1";

    if (tile.hasOwnProperty("_id")) {
      let id: any = tile["_id"];
      let orgs: any = tile["organizationId"];
      let createdOrg: any = tile["createdOrg"];

      if (orgs.length > 0) {
        this.organizationCheck(createdOrg, orgs, (result1: any, result2: any) => {
          if ((result1 === 0 && result2 === 0) || (result1 === 2 && result2 === 2)) {
            // "Save as new copy"
            let obj2: any = !this.utils.isEmptyObject(this.selectedData) ? Object.assign({}, this.selectedData) : {};
            let title: string = this.selectedData.title;

            if (title === obj2["title"]) {
              this.utils.iAlert('error', this.translate.instant('Error'), 'Please Modify the Tile name');
              return false;
            }

            tile["createdOrg"] = this.selectedOrganization;
            tile["organizationId"] = [this.selectedOrganization];
            delete tile["_id"];

          } else if (result1 === 1 && result2 === 0) {
            // "Save and delete original"
            let tileId: string = tile["_id"];
            deleteId = tileId;
            let currOrgs: any = tile["organizationId"];
            let orgIdx: number = currOrgs.indexOf(this.selectedOrganization);
            currOrgs.splice(orgIdx, 1);
            let updateData: any = { "organizationId": currOrgs };
            if (!this.loading) {
              this.loading = true;
              this.errorMessage = '';
              this.requestService.saveData(this.dataType, updateData, (data, error) => {
                if (error) {
                  this.errorMessage = error;
                  this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
                }
                if (data) {
                  this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Updated', 'Dismiss');
                }
                this.loading = false;
              });
            }
            // this.requestService.tileUpdate(tileId, updateData);
            tile["createdOrg"] = this.selectedOrganization;
            tile["organizationId"] = [this.selectedOrganization];
            delete tile["_id"];
          }

          cb(tile, deleteId);
        });
      }
    } else {
      cb(tile, deleteId);
    }
  };
  assignIndexes(blks: any[]) {
    for (let i = 0; i < blks.length; i++) {
      blks[i]["index"] = i;
    }

    return blks;
  };
  arrangeBlocks(currBlks: any[]) {
    let arrangedBlocks: any[] = this.utils.sortArray(currBlks, true, "index");

    for (let i = 0; i < arrangedBlocks.length; i++) {
      delete arrangedBlocks[i]["index"];
    }

    return arrangedBlocks;
  };
  saveTile(tileObj: Object, deleteId?: string, isUpdate?: boolean, isDuplicate?: boolean, updatedId?: string, newTileObj?: Object, langSave?: boolean, langCode?: string, selectData?: boolean, dataToSelect?: string) {
    // console.log('tileObj', tileObj);
    // return;
    if (!this.utils.isEmptyObject(tileObj)) {
      if (!this.loading) {
        let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
        this.loading = true;
        this.errorMessage = '';
        this.requestService.saveData(this.dataType, tileObj, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, 'Dismiss');
          }
          if (data) {
            let resTile = data.results; // please talk to udhay to make this return an object and not array
            let savedBlocks = resTile.blocksData;
            this.loading = false;
            if (!this.utils.isEmptyObject(resTile) && resTile.hasOwnProperty("_id") && !this.utils.isNullOrEmpty(resTile["_id"])) {
              // if (!isUpdate && !isDuplicate && !langSave && !selectData) {
              //   console.log('first isUpdate', isUpdate, 'isDuplicate', isDuplicate, 'langSave', langSave);
              //   let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
              //
              //   this.loaderService.display(false);
              //   this.tile_list.loadData();
              //   this.utils.iAlert('success', '', 'Tile ' + tileMessage + ' successfully');
              //   this.assignBlockData(savedBlocks);
              // } else
              if (!isUpdate && isDuplicate) {
                //console.log('second isUpdate', isUpdate, 'isDuplicate', isDuplicate);
                let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
                this.utils.iAlert('success', '', this.translate.instant('Tile') + 'duplicated successfully');
                this.setTileContent(resTile, isNew, true, true, true);
              } else if (isUpdate && isDuplicate && !selectData) {
                //console.log('third isUpdate', isUpdate, 'isDuplicate', isDuplicate);
                let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
                this.setTileContent(resTile, isNew, true, false, false);
                this.tileSave("", false, true, true);
              } else if (isUpdate && !isDuplicate && selectData) {
                //console.log('fourth isUpdate', isUpdate, 'isDuplicate', isDuplicate);
                this.loaderService.display(false);
                this.selectItem(dataToSelect);
              } else if (isUpdate && !isDuplicate && !selectData) {
                //console.log('fifth isUpdate', isUpdate, 'isDuplicate', isDuplicate);
                let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
                let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
                this.utils.iAlert('success', '', this.translate.instant('Tile') + ' ' + tileMessage + ' successfully');
                if (this.isDialog) {
                  this.onSaveReturn.emit({ _id: resTile._id, name: resTile.title, active: false });
                  this.loaderService.display(false);
                } else {
                  this.setTileContent(resTile, isNew, true, true, true);
                }
              } else if (langSave && !selectData) {
                //console.log('sixth langSave', langSave);
                let isNew: boolean = tileObj.hasOwnProperty("_id") ? false : true;
                let tileMessage: string = tileObj.hasOwnProperty("_id") ? "updated" : "saved";
                this.utils.iAlert('success', '', this.translate.instant('Tile') + ' ' + tileMessage + ' successfully');
                this.setTileContent(resTile, isNew, true, false, true);
                this.languageChange(langCode, true);
                // this.assignBlockData(savedBlocks);
              }
            }

            // if(!isNew){
            // 	this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Updated', 'Dismiss');
            // }else{
            //   this.selectedData['_id'] = data.results._id; // change to the whole object
            // 	this.layoutUtilsService.showNotification(this.dataTypeDisplay + ' Created', 'Dismiss');
            // }
          }
        });
      } else {
        this.loaderService.display(false);
      }
    } else {
      this.loaderService.display(false);
    }
  };
  organizationCheck(createdOrg: string, orgs: any[], cb) {
    var orgsMatchChk = orgs.indexOf(this.selectedOrganization);
    if (orgsMatchChk === -1) {
      var html = '';
      var orgNames = this.getOrganizationName(orgs).split(',');
      html += "<ul style='text-align: left;'>";

      for (let i = 0; i < orgNames.length; i++) {
        html += '<li>' + orgNames[i] + '</li>';
      }

      html += "</ul>";

      this.utils.iQuestions("question", this.translate.instant('Warning'), "This tile was assigned to the organizations : " + html + ".<br>If you choose to modify it, it will create a new copy in this organization.", "Save as new copy", "Close", "", (r) => {
        if (r["value"] === "Save as new copy") {
          cb(0, 0);
        }
      });
    } else if (typeof orgs !== 'string' && orgs[0] !== this.selectedOrganization) {
      this.utils.iQuestions("question", this.translate.instant('Warning'), "This tile was linked originally to <b>" + this.getOrganizationName(orgs[0].split(',')) + "</b>.<br>If you choose to modify it, it will create a new copy.<br>If the original tile is used in one of your Events or Pages, it will be removed. You may use the newly created copy in the Event or page.", "Save and delete original", "Close", "", (r) => {
        if (r["value"] === "Save and delete original") {
          cb(1, 0);
        }
      });
    } else if (typeof orgs !== 'string' && orgs.length > 1 && orgs[0] === this.selectedOrganization) {
      var html = '';
      var orgNames = this.getOrganizationName(orgs).split(',');
      html += "<ul style='text-align: left;'>";

      for (let i = 0; i < orgNames.length; i++) {
        html += '<li>' + orgNames[i] + '</li>';
      }

      html += "</ul>";

      this.utils.iQuestions("question", this.translate.instant('Warning'), "Saving this Tile will modify the content in the following organizations:" + html, "Continue", "Close", "Save as a copy", (r) => {
        if (r["value"] === "Continue") {
          cb(2, 0);
        } else if (r["value"] === "Save as a copy") {
          cb(2, 2);
        }
      });
    } else {

      cb(2, 1);
    }
  };
  getOrganizationName(orgs: string[], currentOrg?: string) {
    let orgNames: any[] = [];

    for (let i = 0; i < this.organizations.length; i++) {
      let currOrg: any = this.organizations[i];
      let orgId: string = currOrg["_id"];
      let orgIdx: number = orgs.indexOf(orgId);

      if (orgIdx !== -1 && (this.utils.isNullOrEmpty(currentOrg) || (currOrg._id !== currentOrg))) {
        orgNames.push(currOrg["name"]);
      }
    }

    return orgNames.join(",");
  };
  assignBlockData(savedBlocks: any[]) {
    let currentBlocks: any[] = this.blocks;
    this.blocks = [];
    for (let i = 0; i < savedBlocks.length; i++) {
      var currSavedBlock = savedBlocks[i];

      if (!this.utils.isEmptyObject(currentBlocks[i]) && currentBlocks[i].hasOwnProperty("existingData")) {
        currentBlocks[i]["existingData"] = currSavedBlock;
        this.blocks.push(currSavedBlock);
      }
    }
  };
  cleanBlockData(savedBlocks: any[]) {
    let currentBlocks: any[] = this.blocks;
    this.blocks = [];
    for (let i = 0; i < savedBlocks.length; i++) {
      var currSavedBlock = savedBlocks[i];

      if (!this.utils.isEmptyObject(currentBlocks[i]) && currentBlocks[i].hasOwnProperty("existingData")) {
        currentBlocks[i]["existingData"] = currSavedBlock;
        this.blocks.push(currSavedBlock);
      }
    }
  };
  setTileContent(tileObj: Object, isNew: boolean, isUpdate?: boolean, isSpinner?: boolean, updateList?: boolean) {
    this.widgetTileReset(false, true, isUpdate);

    this.selectItem(tileObj, this.selectedLanguage);

    if (isSpinner) {
      this.loaderService.display(false);
    }
    if (updateList) {
      this.tile_list.loadData();
    }
  };
}

import {
  Component,
  OnInit,
  Input,
  Output,
  ViewChild,
  ElementRef,
  EventEmitter,
} from "@angular/core";

@Component({
  selector: "app-emoji-slider-field",
  templateUrl: "./emoji-slider-field.component.html",
  styleUrls: ["./emoji-slider-field.component.scss"],
})
export class EmojiSliderFieldComponent implements OnInit {
  @ViewChild("slider") sliderRef: ElementRef;

  @Input() sliderTitle: string;
  @Input() sliderName: string = "testSlider";
  //@Input("sliderValue") sliderValue: string;
  @Input("nativeValue") e_nativeValue: any;
  @Input("enum") datasource: any[];

  @Output() onChanged: EventEmitter<any> = new EventEmitter<any>();

  valueTitle: string;
  constructor() {}

  ngOnInit(): void {
    console.log(this.e_nativeValue);
    this.initialValues(0);
    // console.log('ngOnInit: ' + this.sliderValue);
  }

  ngAfterViewInit(): void {
    // console.log('ngAfterViewInit: ' + this.e_nativeValue);
    // if (this.sliderValue != undefined) {
    // this.sliderRef.nativeElement.value = this.sliderValue;
    // this.changeValue(parseInt(this.sliderValue) * this.datasource.length);
    // } else {
    this.initView(0);
    // }
  }

  initialValues(evalue) {
    let range = this.datasource.length;
    let peace = 100 / range;
    let val = Math.floor(evalue / peace);
    let index = val == range ? val - 1 : val;
    this.valueTitle = this.datasource[index].displayName;
  }

  initView(evalue) {
    let range = this.datasource.length;
    let peace = 100 / range;
    let val = Math.floor(evalue / peace);
    let index = val == range ? val - 1 : val;
    this.valueTitle = this.datasource[index].displayName;
    this.sliderRef.nativeElement.value = evalue;
    this.sliderRef.nativeElement.classList = this.datasource[index].emoji;
    this.sliderRef.nativeElement.style.background = this.generateBackgroundGradient(evalue);
  }

  changeValue(evalue) {
    let range = this.datasource.length;
    let peace = 100 / range;
    let val = Math.floor(evalue / peace);
    let index = val == range ? val - 1 : val;
    this.valueTitle = this.datasource[index].displayName;
    this.sliderRef.nativeElement.classList = this.datasource[index].emoji;
    this.sliderRef.nativeElement.style.background = this.generateBackgroundGradient(evalue);
    this.onChanged.emit(this.datasource[index].Value);
  }

  generateBackgroundGradient(value) {
    if (value < 20) {
      return `linear-gradient(
        90deg,
        rgba(78, 134, 234,${value / 100 + 0.15}) ${value}%,
        rgb(214, 214, 214) ${value}%
      )`;
    }

    return `linear-gradient(
      90deg,
      rgba(78, 134, 234,${value / 100}) ${value}%,
      rgb(214, 214, 214) ${value}%
    )`;
  }
}

<mat-card class="example-card" *ngIf="dataList.length > 0">
	<mat-card-content>
		<div>
			<div class="mainQuestions" *ngFor="let itm of dataList">
				<div class="itemQuestion">
					{{itm.question}}
				</div>
				<div class="dataQuestion" *ngIf="itm.answers">
					<table style="width:100%" border=0 cellspacing=0>
						<tr *ngFor="let itmans of itm.answers;let index = index">
							<td [class.bgWhite]="index%2===0">{{itmans.value}}</td>
							<td [class.bgWhite]="index%2===0">{{itmans.percentage}}</td>
						</tr>
					</table>
				</div>
			</div>
		</div>
	</mat-card-content>
</mat-card>

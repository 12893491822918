import { ModelerData } from "./Modeler-interface";

export class FlowChart {
  initialData: any;

  linkList: {}[] = [];
  stepList: {}[] = [];

  linksMap: Map<string, any> = new Map();
  stepMap: Map<string, any> = new Map();

  number_of_steps: number = 0;

  output: any;
  constructor(data: ModelerData) {
    this.initialData = { ...data };

    this.initCreationProcess(data);
  }

  initCreationProcess(d: ModelerData) {
    this.number_of_steps = d.number_of_steps;

    //check if object has steps
    if (
      this.number_of_steps < 1 &&
      "steps" in d &&
      Object.keys(d.steps).length < 1
    ) {
      let obj: { [param: string]: any } = {};
      obj.id = this.generateId("answers");
      console.log(obj);
    }
  }

  createInitialStep() {}

  createLinks() {}
  createLink() {}

  createSteps() {}
  cerateStep() {}

  generateId(type: string) {
    switch (type) {
      case "answer":
        break;
      case "step":
        break;
    }
  }

  incrementStepNum() {}
}

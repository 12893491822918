import { Component, OnInit, ChangeDetectorRef, Input, ElementRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { fromEvent, BehaviorSubject, merge, Subscription, interval } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RequestService, SubheaderService, LayoutUtilsService, PageScopeService, StoreService, LoaderService } from '../../services';
import { ModalTileCreateDialogComponent } from '../tile-create-dialog/tile-create-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { environment } from '../../../../environments/environment';
// Object path
import * as objectPath from 'object-path';

@Component({
  selector: 'app-lecture-tiles-list',
  templateUrl: './lecture-tiles-list.component.html',
  styleUrls: ['./lecture-tiles-list.component.scss']
})
export class LectureTilesListComponent implements OnInit {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;

  private repeatIntervalSeconds: number = 30;
  public errorMessage: string = '';
  public selectedCategory: string = '-1';
  public tableName: string = 'tile';
  public dataType: string = 'tile';
  public dataTypeDisplay: string = 'Library';
  public UIDisplay: string = 'Content';
  public loading: boolean = false;
  public selectedOrganization: string = "-1";

  public targetName: string = 'tiles';
  public pushedTile: any = undefined;
  public tilesList: any[] = [];
  public dataList: any[] = [];
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);

  pageNumber = 1;

  orderDir = 'desc';
  orderBy = 'createdAt'; // uid

  public searchVal: string = '';

  @Input() hasCategories: boolean = false;
  @Input() pageSize: number = 10;
  @Input() sessionData: any = undefined;
  @Input() selectedData: any = undefined;
  @Input() disablePush: boolean = false;
  @Input() disableAdd: boolean = false;
  @Output() tileAction = new EventEmitter<any>();
  @Output() selectItem = new EventEmitter<any>();
  @Output() pushedItem = new EventEmitter<any>();
  @Output() unPushItem = new EventEmitter<any>();
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  constructor(private requestService: RequestService, private router: Router,
    private activatedRoute: ActivatedRoute,
    private pageScopeService: PageScopeService,
    private subheaderService: SubheaderService,
    private layoutUtilsService: LayoutUtilsService, private translate: TranslateService,
    public dialog: MatDialog, private loaderService: LoaderService) { }
    ngAfterViewInit() {
      const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
        // tslint:disable-next-line:max-line-length
        debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
        distinctUntilChanged(), // This operator will eliminate duplicate values
        tap(() => {
          this.paginatorTotal.next(0);
          this.loadData();
        })
      )
        .subscribe();
      this.subscriptions.push(searchSubscription);
      const paginatorSubscriptions = merge(this.paginator.page).pipe(
        tap(() => {
          this.getTableVariables();
          this.loadData();
        })
      )
        .subscribe();
      this.subscriptions.push(paginatorSubscriptions);
    }
    ngOnInit() {
      this.subscriptions.push(
        this.requestService.currentUserSubject.subscribe((data) => {
          if (data) {
            this.selectedUser = data;
            this.selectedOrganization = this.requestService.orgId;
            this.loadData();
            if (this.sessionData) {
              this.getActiveTile();
            }
          }
        })
    );
    this.subscriptions.push(interval(1000 * this.repeatIntervalSeconds).subscribe((x) => {
      if (environment.production && this.sessionData) {
        this.getActiveTile();
      }
    }));

  }
  /**
   * On Destroy
   */
  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  public selectCategory(data) {
    //  console.log('selectCategory', data);
    this.selectedCategory = data._id;
    this.loadData();
  }
  public selectItemCheck(data) {
    this.selectItem.emit(data);
  }
  public getTableVariables() {
    // this.orderBy = this.sort.active || 'uid';
    // this.orderDir = this.sort.direction || 'asc';
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
  loadData() {
    this.loadDataForSelection();
  }
  loadDataForSelection() {
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      let termConfiguration = this.termConfiguration();

      let filterData: any = {
        '$and': [
          { 'organizationId': { '$eq': this.selectedOrganization } }
        ]
      };

      if (this.selectedCategory !== '-1') {
        filterData['$and'].push({ 'category': { '$eq': this.selectedCategory } });
      }
      let filterObj = { perpage: this.pageSize, page: this.pageNumber, orderBy: this.orderBy, orderDir: this.orderDir, term: termConfiguration, termfields: ['title'], filter: filterData };
      this.requestService.getDataListBySelection('session', this.sessionData._id, filterObj, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          return;
        }
        this.loading = false;
        if (data) {
          this.dataList = data.results;
        }
        this.paginatorTotal.next(data.pagination.total);
      });
    }
  }
  getActiveTile() {
    this.requestService.getActiveTile(this.sessionData._id, (data, error) => {
      if (error) {
        this.pushedTile = undefined;
      }
      if (data) {
        if (data.results && data.results.hasOwnProperty('_id')) {
          this.pushedTile = data.results._id;
          this.selectItemCheck(data.results);
        }
      }
    });
  }
  termConfiguration(): any {
    if(this.searchInput){
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
  public pushToStudent(e, id: any) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }
    if (this.pushedTile !== id) {
      this.pushedItem.emit(id);
    } else {
      if (!this.loading) {
        this.loading = true;
        this.errorMessage = '';
        this.requestService.deactivateTile(this.sessionData._id, id, (data, error) => {
          if (error) {
            this.errorMessage = error;
            this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          }
          this.loading = false;
          if (data) {
            this.pushedTile = undefined;
            this.unPushItem.emit(id);
            this.loadData();
          }
        });
      }
    }
  }
  public activateTile(id: any) {
    this.pushedTile = id;
    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.setActiveTile(this.sessionData._id, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
          this.pushedTile = undefined;
        }
        this.loading = false;
        if (data) {
          this.pushedTile = data.results._id;
        }
      });
    }
  }
  public setToUnDone(e, id: any) {
    if (e) {
      e.stopImmediatePropagation();
      e.preventDefault();
    }

    if (!this.loading) {
      this.loading = true;
      this.errorMessage = '';
      this.requestService.unDoneTile(this.sessionData._id, id, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
        }
        this.loading = false;
        if (data) {
          this.loadData();
        }
      });
    }
  }
  public assignTiles() {
    let data = this.sessionData;

    const dialogRef = this.dialog.open(ModalTileCreateDialogComponent, {
      width: '2200px',
      data: {
        title: this.translate.instant('Attach Content'),
        data: { _id: data._id }
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log(result);
        if (!this.loading) {
          this.loading = true;
          this.errorMessage = '';
          let dataList = [];
          if (data.hasOwnProperty('tiles')) {
            for (let dt of data.tiles) {
              dataList.push({ _id: dt._id, name: dt.name, active: dt.active });
            }
          }
          dataList.push(result);
          let obg = { tiles: dataList };
          this.requestService.assignTilesToLecture(data._id, obg, (data, error) => {
            if (error) {
              this.errorMessage = error;
              this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
            }
            this.loading = false;
            if (data) {
              this.loadData();
            } else {
              this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
            }
          });
        }
      }
    });
  }
}

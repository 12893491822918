import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MeanrequestService } from "src/app/shared";

@Component({
  selector: "app-edit-modeler-dialog",
  templateUrl: "./edit-modeler-dialog.component.html",
  styleUrls: ["./edit-modeler-dialog.component.scss"],
})
export class EditModelerDialogComponent implements OnInit {
  answerList = [];
  //error to display if any field is left out
  hasError: boolean = false;
  errorMsg: string = "";

  //TODO: generate characters form incoming data
  characterList = [
    { name: "john", ref: "main" },
    { name: "jane", ref: "secondary 1" },
    { name: "johns", ref: "secondary 2" },
  ];

  sceneLines = [];

  strategies: string[];

  mediaList = [
    { name: "media 1", path: "./../", type: "audio" },
    { name: "media 2", path: "./../", type: "video" },
  ];

  type: string;

  question_text: string;

  is_active: boolean;

  title: string;

  tile_url: string;

  //owner Id
  //TODO:get owner from database
  owner: string = "dummyOwnerId";

  constructor(
    public dialogRef: MatDialogRef<EditModelerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public meanReq: MeanrequestService
  ) {}

  ngOnInit(): void {
    this.checkData();
  }

  checkData(): void {
    this.strategies = this.meanReq.getStrategies() || [];
    this.sceneLines = this.data.lines || [];
    this.answerList = this.data.answers || [];
    this.type = this.data.type || "";
    this.question_text = this.data.question_text || "";
    this.is_active = this.data.is_active || "";
    this.tile_url = this.data.tile_url || "";
  }

  cancelClick() {
    this.dialogRef.close(null);
  }

  addQuestion() {
    let obj = {
      is_last: false,
    };
    this.answerList.push(obj);
  }

  deleteAnswer(index) {
    this.answerList.splice(index, 1);
  }

  addSceneLine() {
    let obj = {};
    this.sceneLines.push(obj);
  }

  deleteSceneLine(index) {
    this.sceneLines.splice(index, 1);
  }

  selectMedia(lineIndex, media) {
    this.sceneLines[lineIndex].media = media;
  }

  getDialogTitle() {
    return `Step ${this.data._id.replace(/STP_/gi, "")}`;
  }

  cleanObject(obj): {} {
    for (const key in obj) {
      if (
        obj[key] == null ||
        obj[key] == "" ||
        obj[key] == undefined ||
        obj[key] == {} ||
        obj[key] == []
      ) {
        delete obj[key];
      }
    }

    //check if the object was converted to an answer
    //then remove the next_step property to avoid conflict
    if (obj.type == "question") {
      if (obj.hasOwnProperty("next_step")) {
        delete obj.next_step;
      }
    }

    return obj;
  }

  confirmClick() {
    //set properties to the imported data
    this.data.lines = this.sceneLines || [];
    this.data.answers = this.answerList || [];
    this.data.type = this.type || "";
    this.data.question_text = this.question_text || "";
    this.data.is_active = this.is_active || "";
    this.data.tile_url = this.tile_url || "";

    //clean data document from empty properties
    this.data = this.cleanObject(this.data);

    //close dialog and return edited data document
    this.dialogRef.close(this.data);
  }
}

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { DialogData } from "src/app/shared";

@Component({
  selector: "app-delete-modeler-dialog",
  templateUrl: "./delete-modeler-dialog.component.html",
  styleUrls: ["./delete-modeler-dialog.component.scss"],
})
export class DeleteModelerDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DeleteModelerDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {}

  /**
   * @description gets the number of the selected node
   * @returns {string} step or answer number
   */
  getStepNumber() {
    //check if the object has an id
    if (this.data.hasOwnProperty("_id")) {
      //check if the id type is answer
      if (this.data._id.includes("ANS_")) {
        return `Step ${this.data._id.replace(/ANS_/gi, "")}`;
      }

      //return step number if the node is an answer
      return `Step ${this.data._id.replace(/STP_/gi, "")}`;
    }

    //if it does not have an id return only 'step'
    return "Step";
  }

  /**
   * @description triggers if the dialog was canceled
   */
  onNoClick() {
    this.dialogRef.close(false);
  }

  /**
   * @description triggers if the dialog was
   */
  onConfirmClick() {
    this.dialogRef.close(true);
  }
}

import { Component, Inject, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

interface ViewDialogData {
	title: string;
	data: any;
	confirmData: any;
}

@Component({
	selector: 'app-tile-select-dialog-modal',
	templateUrl: './tile-select-dialog.component.html',
	styleUrls: ['./tile-select-dialog.component.scss']
})
export class TileSelectDialogComponent implements OnInit {
	constructor(public dialogRef: MatDialogRef<TileSelectDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		//console.log('ViewDialogData', data);
	}

	ngOnInit() {
	}
	selectItemCheck(data) {
		this.dialogRef.close(data);
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
}

<form #personForm="ngForm" *ngIf="metaFieldSetting && data" class="layoutDialog">
	<div class="col-xl-12" *ngIf="data.data">
		<!-- <h1 mat-dialog-title>{{ 'Edit' | translate}} <span *ngIf="!showMobile">Desktop</span><span *ngIf="showMobile">Mobile</span> {{data.title}}</h1> -->
		<div>
			<div *ngIf="data.modalSetting">
	      <div *ngIf="data.modalSetting.fields">
					<div >
						<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
					      <div fxFlex>
									<div class="fieldInput" style="width:100%">
											<mat-form-field style="width:100%">
												<input matInput [required]="!metaFieldSetting['name']['nullable']" name="name" [(ngModel)]="data.data['name']" placeholder="{{metaFieldSetting['name']['displayName'] | translate}}" [disabled]="templateDisabled" >
											</mat-form-field>
									</div>
								</div>
								<div fxFlex>
									<div class="fieldInput actionCssTop" style="float: right;" >
										<button mat-raised-button  (click)="setShowMobileReturn($event)" ><span *ngIf="showMobile">Desktop</span><span *ngIf="!showMobile">Mobile</span> {{data.title}}</button>
										<span >
											<button *ngIf="!templateDisabled" mat-raised-button (click)="loadDefaultSettings('settings')" >{{'Reset' | translate}}</button>
											<button *ngIf="templateDisabled" mat-raised-button (click)="loadTemplateSettings('settings')" >{{'Reset' | translate}}</button>
											<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')" [disabled]="loading" >{{'Create' | translate}}</button>
											<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading" >{{'Save' | translate}}</button>
										</span>
									</div>
								</div>
							</div>
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="!showMobile">
						     <div fxFlex >
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
											<div fxFlex="30">
												<div class="fieldInput" *ngIf="settingObject">
														<mat-form-field>
															<input matInput type="number" [required]="true" name="gap" [(ngModel)]="settingObject['gap']" placeholder="{{'Gap Between Columns' | translate}} (VW)" >
														</mat-form-field>
												</div>
												<div class="fieldInput" *ngIf="settingObject">
														<mat-form-field>
															<input matInput type="number" [required]="true" name="columns" [(ngModel)]="columnsLength" min="1" max="6" placeholder="{{'Number of Columns' | translate}}" (change)="changeColumns($event.target.value);" [disabled]="templateDisabled">
														</mat-form-field>
												</div>

											</div>
								      <div fxFlex="70">
												<div class="fieldInputRight" *ngIf="settingObject">
													<mat-icon *ngIf="!settingObject['enableScreenShareButton']" (click)="settingObject['enableScreenShareButton']=true" title="{{'Enable Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['enableScreenShareButton']" (click)="settingObject['enableScreenShareButton']=false" title="{{'Enable Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													<label>{{'Enable Screen Share' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject">
													<mat-icon *ngIf="!settingObject['enableFullScreen']" (click)="settingObject['enableFullScreen']=true" title="{{'Enable Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['enableFullScreen']" (click)="settingObject['enableFullScreen']=false" title="{{'Enable Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													<label>{{'Enable Full Screen' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject">
													<mat-icon *ngIf="!settingObject['enableCaptureScreen']" (click)="settingObject['enableCaptureScreen']=true" title="{{'Enable Screen Capture' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['enableCaptureScreen']" (click)="settingObject['enableCaptureScreen']=false" title="{{'Enable Screen Capture' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													<label>{{'Enable Screen Capture' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject">
													<mat-icon *ngIf="!settingObject['enableRaiseHand']" (click)="settingObject['enableRaiseHand']=true" title="{{'Enable Hand-Raise' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['enableRaiseHand']" (click)="settingObject['enableRaiseHand']=false" title="{{'Enable Hand-Raise' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													<label >{{'Enable Hand-Raise' | translate}}</label>
												</div>
											</div>
									</div>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject" >
								      <div fxFlex cdkDropListGroup>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="mb-20" *ngIf="!templateDisabled">
											      <div fxFlex>
															<div cdkDropListOrientation="horizontal" class="example-list-horizontal" cdkDropList (cdkDropListDropped)="drop($event, true)" [cdkDropListData]="componentsList" id="mainMenu" name="mainMenu">
															  <div class="example-box-horizontal" *ngFor="let cmp of componentsList" cdkDrag>
																	{{componentsName[cmp.name]['displayName']}}
																	<div class="example-handle">
																    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
																      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
																      <path d="M0 0h24v24H0z" fill="none"></path>
																    </svg>
																  </div>
																</div>
															</div>
														</div>
												</div>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="{{settingObject['gap']}}VW"  *ngIf="settingObject.hasOwnProperty('version') && settingObject.version == defaultSetting.version">
											      <div fxFlex="{{col['width']}}" *ngFor="let col of settingObject['columns'];let index = index">
															<div class="example-list">
																<div class="dropTitle">
																		Column {{index + 1}}
																		<div class="contentData">
																			<!-- <label for="gap{{index}}">{{'Vertical gap' | translate}}:</label>
																			<input name="gap{{index}}" id="gap{{index}}" placeholder="" [value]="col['gap']" (change)="col['gap']=$event.target.value" > -->
																			<span>
																				<label style="margin-left: 10px;" for="width{{index}}">{{'Width' | translate}} %</label>
																				<input type="number" name="width{{index}}" id="width{{index}}" placeholder="" [value]="col['width']" (change)="changeWidth($event.target.value, index)" min="0" max="100" [readonly]="templateDisabled">
																			</span>
																			<span>
																				<label style="margin-left: 10px;" for="rows{{index}}">{{'Rows' | translate}}</label>
																				<input type="number" name="rows{{index}}" id="rows{{index}}" placeholder="" [value]="col.rows.length" (change)="changeRows($event.target.value, index)" [readonly]="templateDisabled">
																			</span>
																			<span>
																				<label style="margin-left: 10px;" for="rowGap{{index}}">{{'Gap (VH)' | translate}}</label>
																				<input type="number" name="rowGap{{index}}" id="rowGap{{index}}" placeholder="" [value]="col.rowGap" (change)="col.rowGap = $event.target.value" [readonly]="templateDisabled">
																			</span>
																	</div>
																</div>
																<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px"
																*ngFor="let row of settingObject['columns'][index]['rows'];let rowIndex = index" class="example-box-row" [style.marginBottom]="col.rowGap + 'VH'" >
																	<div fxFlex="none" style="border-right: 1px solid #ccc;padding:5px">
																		<div>
																			<mat-icon (click)="editRowH(index, rowIndex)" title="{{'Alter Row' | translate}} {{rowIndex + 1}} Horizontal Alignment">more_horiz</mat-icon>
																		</div>
																		<div>
																			<mat-icon (click)="editRowV(index, rowIndex)" title="{{'Alter Row' | translate}} {{rowIndex + 1}} Vertical Alignment">more_vert</mat-icon>
																		</div>
																	</div>
																	<div fxFlex
																	cdkDropList (cdkDropListDropped)="drop($event)" cdkDropListOrientation="horizontal" [cdkDropListData]="settingObject['columns'][index]['rows'][rowIndex]['components']" fxLayoutAlign="{{row['halign']}} {{row['valign']}}"
																	id="rowsmenu{{index}}{{rowIndex}}" name="rowsmenu">
																	  <div class="example-box example-box-left" [class.example-box-disable]="templateDisabled" *ngFor="let cmp of row['components'];let subIndex = index" cdkDrag [cdkDragDisabled]="templateDisabled"
																		[style.margin]="cmp['margin']">
																			<div class="contentDataMain">
																				<span>{{componentsName[cmp.name]['displayName']}}</span>
																				<div class="contentDataActions">
																					<span *ngIf="cmp['name'] === 'tile' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																					<mat-icon *ngIf="cmp['name'] === 'tile'" [class.green]="cmp.metadata.id !== ''" (click)="editTile(index, rowIndex, subIndex)" title="{{'Select Tile' | translate}}">edit</mat-icon>
																					<span *ngIf="cmp['name'] === 'guest-camera' && templateDisabled && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																					<mat-icon *ngIf="cmp['name'] === 'guest-camera' && templateDisabled" [class.green]="cmp.metadata.id !== ''" (click)="editGuest(index, rowIndex, subIndex)" title="Select {{'Guest' | translate}}">edit</mat-icon>
																					<span *ngIf="cmp['name'] === 'billboard' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																					<mat-icon *ngIf="cmp['name'] === 'billboard'" [class.green]="cmp.metadata.id !== ''" (click)="editBillboard(index, rowIndex, subIndex)" title="{{'Select Billboard' | translate}}">edit</mat-icon>
																					<!-- <mat-icon *ngIf="cmp['align']==='left'" (click)="cmp['align']='right'" title="{{'Active' | translate}}">toggle_off</mat-icon>
																					<mat-icon *ngIf="cmp['align'] === 'right'" (click)="cmp['align']='left'" title="{{'Active' | translate}}">toggle_on</mat-icon> -->
																					<mat-icon *ngIf="!cmp['active']" (click)="cmp['active']=true" title="{{'Active' | translate}}">visibility_off</mat-icon>
																					<mat-icon *ngIf="cmp['active']" (click)="cmp['active']=false" title="{{'Active' | translate}}">visibility</mat-icon>
																					<mat-icon *ngIf="!templateDisabled" (click)="removeCmp(index, rowIndex, subIndex)" title="{{'Delete' | translate}}">delete_forever</mat-icon>
																				</div>
																			</div>
																			<div class="contentData">
																				<app-margin-view [marginValue]="cmp['margin']" (returnMarginValue)="cmp['margin']=$event"></app-margin-view>
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'questions' || cmp['name'] === 'chat' || cmp['name'] === 'attendees'">
																				<label for="backgroundColor{{subIndex}}">{{'Background Color' | translate}}:</label>
																				<input name="backgroundColor{{subIndex}}" placeholder=""  [value]="cmp['backgroundColor']" (change)="cmp['backgroundColor']=$event.target.value">
																			</div>
																			<div class="contentData">
																				<label for="width{{subIndex}}">{{'Width' | translate}} %</label>
																				<input type="number" name="width{{subIndex}}" placeholder=""  [value]="cmp['width']" (change)="changeRowWidth($event.target.value, index, rowIndex, subIndex )">
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'tile' || cmp['name'] === 'chat' || cmp['name'] === 'attendees' || cmp['name'] === 'defaultvideo'">
																				<label for="height{{subIndex}}">{{'Height' | translate}} (VH)</label>
																				<input type="number" name="height{{subIndex}}" placeholder="" [value]="cmp['height']" (change)="cmp['height']=$event.target.value">
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
																				<label for="url{{subIndex}}">{{'Video Url' | translate}}:</label>
																				<input name="url{{subIndex}}" placeholder="" [value]="cmp['url']" (change)="cmp['url']=$event.target.value">
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'questions' || cmp['name'] === 'attendees' || cmp['name'] === 'chat'">
																				<label for="expanded{{subIndex}}">{{'Expanded' | translate}}:</label>
																				<mat-icon *ngIf="!cmp['expanded']" (click)="cmp['expanded']=true" title="{{'Expanded' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
																				<mat-icon *ngIf="cmp['expanded']" (click)="cmp['expanded']=false" title="{{'Expanded' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
																				<label for="frame{{subIndex}}">{{'Frame' | translate}}:</label>
																				<input name="frame{{subIndex}}" placeholder="" [value]="cmp['frame']" (change)="cmp['frame']=$event.target.value">
																			</div>
																			<div class="contentData" *ngIf="cmp['name'] === 'screenshare'">
																				<label for="message{{subIndex}}">{{'Message' | translate}}:</label>
																				<input name="message{{subIndex}}" placeholder="" [value]="cmp['message']" (change)="cmp['message']=$event.target.value">
																			</div>
																		</div>
																	</div>
																</div>
															</div>
														</div>
												</div>
											</div>
									</div>
								</div>
						</div>
						<!-- Mobile Version -->
							<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="showMobile">
						     <div fxFlex>
										<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px">
											<div fxFlex="30">
												<div class="fieldInput" *ngIf="settingObject['mobile']">
														<mat-form-field>
															<input type="number" matInput [required]="true" name="gap" [(ngModel)]="settingObject['mobile']['gap']" placeholder="{{'Gap Between Columns' | translate}} (VW)" >
														</mat-form-field>
												</div>
												<div class="fieldInput" *ngIf="settingObject['mobile']">
														<mat-form-field>
															<input matInput type="number" [required]="true" name="mobilecolumns" value="1" placeholder="{{'Number of Columns' | translate}}" [disabled]="true">
														</mat-form-field>
												</div>

											</div>
								      <div fxFlex="70">
												<div class="fieldInputRight" *ngIf="settingObject['mobile']">
													<mat-icon *ngIf="!settingObject['mobile']['enableScreenShareButton']" (click)="settingObject['mobile']['enableScreenShareButton']=true" title="{{'Enable Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['mobile']['enableScreenShareButton']" (click)="settingObject['mobile']['enableScreenShareButton']=false" title="{{'Enable Screen Share' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
														<label>{{'Enable Screen Share' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject['mobile']">
													<mat-icon *ngIf="!settingObject['mobile']['enableFullScreen']" (click)="settingObject['mobile']['enableFullScreen']=true" title="{{'Enable Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['mobile']['enableFullScreen']" (click)="settingObject['mobile']['enableFullScreen']=false" title="{{'Enable Full Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
														<label>{{'Enable Full Screen' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject['mobile']">
													<mat-icon *ngIf="!settingObject['mobile']['enableCaptureScreen']" (click)="settingObject['mobile']['enableCaptureScreen']=true" title="{{'Enable Capture Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['mobile']['enableCaptureScreen']" (click)="settingObject['mobile']['enableCaptureScreen']=false" title="{{'Enable Capture Screen' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
														<label>{{'Enable Capture Screen' | translate}}</label>
												</div>
												<div class="fieldInputRight" *ngIf="settingObject['mobile']">
													<mat-icon *ngIf="!settingObject['mobile']['enableRaiseHand']" (click)="settingObject['mobile']['enableRaiseHand']=true" title="{{'Enable Raise Hand' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
													<mat-icon *ngIf="settingObject['mobile']['enableRaiseHand']" (click)="settingObject['mobile']['enableRaiseHand']=false" title="{{'Enable Raise Hand' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
													<label>{{'Enable Raise Hand' | translate}}</label>
												</div>
											</div>
									</div>
									<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" *ngIf="settingObject['mobile']">
								      <div fxFlex cdkDropListGroup>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" class="mb-20" *ngIf="!templateDisabled">
											      <div fxFlex>
															<div cdkDropListOrientation="horizontal" class="example-list-horizontal" cdkDropList (cdkDropListDropped)="drop($event, true)" [cdkDropListData]="mobilecomponentsList">
															  <div class="example-box-horizontal" *ngFor="let cmp of mobilecomponentsList" cdkDrag>
																	{{componentsName[cmp.name]['displayName']}}
																	<div class="example-handle">
																    <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
																      <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
																      <path d="M0 0h24v24H0z" fill="none"></path>
																    </svg>
																  </div>
																</div>
															</div>
														</div>
												</div>
												<div fxLayout="row" fxLayout.lt-md="column" fxFlex fxLayoutGap="20px" >
											      <div fxFlex="23vw" *ngFor="let col of settingObject['mobile']['columns'];let index = index" class="centerMargin">
															<div class="example-list" cdkDropList (cdkDropListDropped)="drop($event)" [cdkDropListData]="settingObject['mobile']['columns'][index]['components']">
																<div class="dropTitle">
																		<!-- Column {{index + 1}} -->
																		<div class="contentData" style="visibility:hidden">
																			<label style="margin-left: 10px;" for="width{{index}}">{{'Width' | translate}} %</label>
																			<input type="number" name="width{{index}}" id="width{{index}}" placeholder=""  [value]="col['width']" (change)="changeWidthMobile($event.target.value, index)" >
																			<label style="margin-left: 10px;" for="rowGap{{index}}">{{'Row Gap (VH)' | translate}}</label>
																			<input type="number" name="rowGap{{index}}" id="rowGap{{index}}" placeholder="" [value]="col.rowGap" (change)="col.rowGap = $event.target.value" [readonly]="templateDisabled">
																	</div>
																</div>
															  <div class="example-box example-box-row" [class.example-box-disable]="templateDisabled" *ngFor="let cmp of col['components'];let subIndex = index" cdkDrag [cdkDragDisabled]="templateDisabled" [style.margin]="cmp['margin']">
																	<div class="contentDataMain">
																		{{componentsName[cmp.name]['displayName']}}
																		<div class="contentDataActions">
																			<span *ngIf="cmp['name'] === 'tile' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																			<mat-icon *ngIf="cmp['name'] === 'tile'" [class.green]="cmp.metadata.id !== ''" (click)="editTileMobile(index, subIndex)" title="{{'Select Tile' | translate}}">edit</mat-icon>
																			<span *ngIf="cmp['name'] === 'guest-camera' && templateDisabled && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																			<mat-icon *ngIf="cmp['name'] === 'guest-camera' && templateDisabled" [class.green]="cmp.metadata.id !== ''" (click)="editGuestMobile(index, subIndex)" title="Select {{'Guest' | translate}}">edit</mat-icon>
																			<span *ngIf="cmp['name'] === 'billboard' && cmp.metadata.name !== ''">{{cmp.metadata.name}}</span>
																			<mat-icon *ngIf="cmp['name'] === 'billboard'" [class.green]="cmp.metadata.id !== ''" (click)="editBillboardMobile(index, subIndex)" title="{{'Select Billboard' | translate}}">edit</mat-icon>
																			<mat-icon *ngIf="!cmp['active']" (click)="cmp['active']=true" title="{{'Active' | translate}}">visibility_off</mat-icon>
																			<mat-icon *ngIf="cmp['active']" (click)="cmp['active']=false" title="{{'Active' | translate}}">visibility</mat-icon>
																			<mat-icon *ngIf="!templateDisabled" (click)="removeMobileCmp(index, subIndex)" title="{{'Delete' | translate}}">delete_forever</mat-icon>
																		</div>
																	</div>
																	<div class="contentData">
																		<!-- <label for="margin{{subIndex}}">{{'Margin' | translate}} (TRBL):</label>
																		<input name="margin{{subIndex}}" placeholder=""  [value]="cmp['margin']" (change)="cmp['margin']=$event.target.value"> -->
																		<app-margin-view [marginValue]="cmp['margin']" (returnMarginValue)="cmp['margin']=$event"></app-margin-view>
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'questions' || cmp['name'] === 'chat' || cmp['name'] === 'attendees'">
																		<label for="backgroundColor{{subIndex}}">{{'Background Color' | translate}}:</label>
																		<input name="backgroundColor{{subIndex}}" placeholder=""  [value]="cmp['backgroundColor']" (change)="cmp['backgroundColor']=$event.target.value">
																	</div>
																	<div class="contentData">
																		<label for="width{{subIndex}}">{{'Width' | translate}} %</label>
																		<input type="number"  name="width{{subIndex}}" placeholder="" [value]="cmp['width']" (change)="cmp['width']=$event.target.value">
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'billboard' || cmp['name'] === 'tile' || cmp['name'] === 'chat' || cmp['name'] === 'attendees' || cmp['name'] === 'defaultvideo'">
																		<label for="height{{subIndex}}">{{'Height' | translate}} (VH)</label>
																		<input type="number" name="height{{subIndex}}" placeholder="" [value]="cmp['height']" (change)="cmp['height']=$event.target.value">
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
																		<label for="url{{subIndex}}">{{'Video Url' | translate}}:</label>
																		<input name="url{{subIndex}}" placeholder="" [value]="cmp['url']" (change)="cmp['url']=$event.target.value">
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'attendees' || cmp['name'] === 'chat'">
																		<label for="expanded{{subIndex}}">{{'Expanded' | translate}}:</label>
																		<mat-icon *ngIf="!cmp['expanded']" (click)="cmp['expanded']=true" title="{{'Expanded' | translate}}" style="height: 30px;vertical-align: middle;">check_box_outline_blank</mat-icon>
																		<mat-icon *ngIf="cmp['expanded']" (click)="cmp['expanded']=false" title="{{'Expanded' | translate}}" style="height: 30px;vertical-align: middle;">check_box</mat-icon>
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'defaultvideo'">
																		<label for="frame{{subIndex}}">{{'Frame' | translate}}:</label>
																		<input name="frame{{subIndex}}" placeholder="" [value]="cmp['frame']" (change)="cmp['frame']=$event.target.value">
																	</div>
																	<div class="contentData" *ngIf="cmp['name'] === 'screenshare'">
																		<label for="message{{subIndex}}">{{'Message' | translate}}:</label>
																		<input name="message{{subIndex}}" placeholder="" [value]="cmp['message']" (change)="cmp['message']=$event.target.value">
																	</div>
																</div>
															</div>
														</div>
												</div>
											</div>
									</div>
								</div>
						</div>
					</div>
	      </div>
	    </div>
		</div>
		<!-- <div class="actionCss" *ngIf="templateDisabled">
				<button color="danger" mat-raised-button (click)="closeModal(undefined)">{{'Close' | translate}}</button>
				<button mat-raised-button (click)="loadTemplateSettings('settings')" >{{'Reset' | translate}}</button>
				<button *ngIf="!data.data._id" mat-raised-button (click)="saveData('create')" [disabled]="loading" >{{'Create' | translate}}</button>
				<button *ngIf="data.data._id" mat-raised-button (click)="saveData('edit')" [disabled]="loading" >{{'Save' | translate}}</button>
		</div> -->
	</div>
</form>

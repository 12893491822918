import { environment } from '../environments/environment';
export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      items: [
        {
          title: "Teacher Dashboard",
          root: true,
          icon: "dashboard",
          page: "/teacher-dashboard",
          permission: [environment.customKeys.roleAdmin],
          bullet: "dot"
        },
        {
          title: "Final Reflections",
          root: true,
          icon: "work_outline",
          page: "/mytrainings",
          permission: [environment.customKeys.roleAdmin],
          bullet: "dot"
        },
        {
          title: "Reflections During Play",
          root: true,
          icon: "work_outline",
          page: "/deq",
        },
        {
          title: 'Admin',
          root: true,
          bullet: 'dot',
          icon: 'add',
          target: 'admin',
          permission: [environment.customKeys.roleAdmin],
          submenu: [
            {
              title: 'Users',
              page: '/admin/users',
            },
            {
              title: 'Characters',
              page: '/admin/character',
            },
            {
              title: "Opening Acts",
              page: "/admin/openingact",
            },
            {
              title: "Assessment",
              root: true,
              page: "/deq/true",
            },
            {
              title: "Training Instance",
              root: true,
              page: "/mytrainings/true",
            },
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}

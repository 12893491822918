<h3 mat-dialog-title>{{ getDialogTitle() }}</h3>
<div mat-dialog-content>
  <div class="content-container">
    <div class="radio-group-container">
      <label for="stepTypeOptions">Step Type</label>
      <mat-radio-group
        class="horizontal-radio-group"
        aria-labelledby="stepTypeOptions"
        [(ngModel)]="type"
      >
        <mat-radio-button class="radio-button" value="display">Display</mat-radio-button>
        <mat-radio-button class="radio-button" value="tiles">Tiles</mat-radio-button>
        <mat-radio-button class="radio-button" value="question">Question</mat-radio-button>
      </mat-radio-group>
    </div>

    <!----------------------------SCENE LINES------------------------------->
    <hr />

    <div class="answer-tree-group my-1" *ngIf="type == 'display'">
      <mat-accordion>
        <mat-expansion-panel *ngFor="let line of sceneLines; let i = index">
          <mat-expansion-panel-header>
            <mat-panel-title> Line {{ i + 1 }} </mat-panel-title>
            <mat-panel-description> create in scene script order </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="flex-column justify-content-center">
            <mat-form-field class="mx-3">
              <mat-label>Character</mat-label>
              <mat-select [(ngModel)]="sceneLines[i].character">
                <mat-option *ngFor="let character of characterList" [value]="character">
                  {{ character.name }} - {{ character.ref }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field class="mx-3">
              <mat-label>Line</mat-label>
              <textarea matInput [(ngModel)]="sceneLines[i].line"></textarea>
            </mat-form-field>

            <div class="media-field mx-3">
              <div
                *ngFor="let item of mediaList; let j = index"
                class="media-item"
                title="{{ item.name }}"
                (click)="selectMedia(i, item)"
              >
                <img
                  *ngIf="item.type == 'video'"
                  src="https://cdn4.iconfinder.com/data/icons/48-bubbles/48/23.Videos-512.png"
                  alt=""
                />
                <img
                  *ngIf="item.type == 'audio'"
                  src="https://cdn2.iconfinder.com/data/icons/freecns-cumulus/16/519586-083_Music-512.png"
                  alt=""
                />
                <span *ngIf="sceneLines[i].media == item"
                  ><img
                    src="https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Check_green_icon.svg/1200px-Check_green_icon.svg.png"
                    alt=""
                /></span>
              </div>
            </div>

            <button
              style="width: 25%; align-self: center; margin-bottom: 15px"
              mat-raised-button
              color="primary"
              (click)="deleteSceneLine(i)"
            >
              Delete
            </button>
          </div>
        </mat-expansion-panel>
      </mat-accordion>

      <div class="add-question-button" (click)="addSceneLine()">
        <span> + Add new line</span>
      </div>
    </div>
    <!----------------------------SCENE LINES END------------------------------->

    <!-----------------------------TILES BLOCK------------------------------->

    <mat-form-field *ngIf="type == 'tiles'">
      <mat-label>URL</mat-label>
      <textarea matInput [(ngModel)]="tile_url"></textarea>
    </mat-form-field>
    <!-----------------------------TILES BLOCK END------------------------------->

    <!-----------------------------QUESTION BLOCk------------------------------->

    <div *ngIf="type == 'question'">
      <mat-form-field class="w-100">
        <mat-label>Question</mat-label>
        <textarea matInput [(ngModel)]="question_text"></textarea>
      </mat-form-field>

      <div class="answer-tree-group">
        <mat-accordion>
          <mat-expansion-panel *ngFor="let ans of answerList; let i = index">
            <mat-expansion-panel-header>
              <mat-panel-title> Answer {{ i + 1 }} </mat-panel-title>
              <mat-panel-description> New Answer Step </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="flex-column">
              <mat-form-field class="mx-3">
                <mat-label>Answer Text</mat-label>
                <textarea matInput [(ngModel)]="answerList[i].answer_text"></textarea>
              </mat-form-field>

              <mat-form-field class="mx-3">
                <mat-label>Feedback</mat-label>
                <input matInput [(ngModel)]="answerList[i].feedback" />
              </mat-form-field>

              <mat-form-field class="mx-3">
                <mat-label>Strategies</mat-label>
                <mat-select [(ngModel)]="answerList[i].strategies" multiple>
                  <mat-option *ngFor="let strategy of strategies" [value]="strategy" required>{{
                    strategy
                  }}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field class="mx-3">
                <mat-label>Points</mat-label>
                <input matInput type="number" min="1" [(ngModel)]="answerList[i].points" />
              </mat-form-field>

              <button
                style="width: 25%; align-self: center; margin-bottom: 15px"
                mat-raised-button
                color="primary"
                (click)="deleteAnswer(i)"
              >
                Delete
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>

        <div class="add-question-button" (click)="addQuestion()">
          <span> + Add new answer</span>
        </div>
      </div>
    </div>

    <div class="flex-column my-1">
      <mat-checkbox class="mx-3 my-1" [(ngModel)]="is_active">Disabled</mat-checkbox>
    </div>

    <!-----------------------------QUESTION BLOCK END------------------------------->
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button (click)="cancelClick()">cancel</button>
  <button mat-raised-button (click)="confirmClick()" cdkFocusInitial>confirm</button>
</div>

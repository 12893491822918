<div class='ili-panel linkDescription_panel'>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.name" class="form-control input-sm" type="text" placeholder="Name">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.description" class="form-control input-sm" type="text" placeholder="Description">
  </mat-form-field>
  <mat-form-field>
    <input matInput [(ngModel)]="block.data.link" class="form-control input-sm" type="text" placeholder="Link">
  </mat-form-field>
</div>

export const environment = {
  production: false,
  serverMainUrl: 'https://staging.ili.ms/',
  serverTileUrl: 'https://stellar.staging.ili.ms/',
  serverUrl: 'https://staging.prf.api.stellar.online/api/',
  serverUrlNoSlash: 'https://staging.prf.api.stellar.online/api',
  vimeoUrl: 'https://player.vimeo.com/video/',
  serverHostUrl: 'https://teacher-toolbox.interactivelife.me',
  serverredirectUrl: 'https://www.interactivelife.com/',
  aliveAppIntervalMinutes: 90, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 30,
  orgId: '5e85c5820c22d9223a15d489',
  useIntro: false,
  lockSite: false,
  hasRegisteration: false,
  hasListingDirectory: false,
  identityName: 'Training',
  serverName: 'PRF 4 Teachers',
  orgType: 'training',
  subServerName: 'Training Toolbox',
  orgSubType: 'school',
  profileImageURL: '',
  tr_id: '5f1922da3904fd0c20ebc8e5',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '',// Student
    roleEdit: '5eb13826d3123a407c7c97ce',// Teacher
  },
  customKeysView: {
    Admin: 'Admin',
    Student: 'View',
    Teacher: 'Teacher',
  },
  tokBox: {
    apiKey: '46643372'
  },

  camundaRESTEngineURL: 'https://camunda.interactivelife.me/engine-rest',
  // camundaRESTEngineURL: 'http://localhost:8080/engine-rest',
  defaultEngine: 'default',
  Diagramkey: 'Process_0r5bp77'
};

import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MeanrequestService } from "src/app/shared";

@Component({
  selector: "app-new-modeler-step-dialog",
  templateUrl: "./new-modeler-step-dialog.component.html",
  styleUrls: ["./new-modeler-step-dialog.component.scss"],
})
export class NewModelerStepDialogComponent implements OnInit {
  answerList = [];
  //error to display if any field is left out
  hasError: boolean = false;
  errorMsg: string = "";

  //TODO: generate characters form incoming data
  characterList = [
    { name: "john", ref: "main" },
    { name: "jane", ref: "secondary 1" },
    { name: "johns", ref: "secondary 2" },
  ];

  strategies: string[];

  sceneLines = [];
  mediaList = [
    { name: "media 1", path: "./../", type: "audio" },
    { name: "media 2", path: "./../", type: "video" },
  ];

  //owner Id
  //TODO:get owner from database
  owner: string = "dummyOwnerId";
  constructor(
    public dialogRef: MatDialogRef<NewModelerStepDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public meanReq: MeanrequestService
  ) {}

  ngOnInit(): void {
    this.strategies = this.meanReq.getStrategies();
  }

  cancelClick() {
    this.dialogRef.close(null);
  }

  addQuestion() {
    let obj = {
      is_last: false,
    };
    this.answerList.push(obj);
  }

  deleteAnswer(index) {
    this.answerList.splice(index, 1);
  }

  addSceneLine() {
    let obj = {};
    this.sceneLines.push(obj);
  }

  deleteSceneLine(index) {
    this.sceneLines.splice(index, 1);
  }

  selectMedia(lineIndex, media) {
    this.sceneLines[lineIndex].media = media;
  }

  confirmClick(d) {
    if (d.type == "question") {
      d.answers = this.answerList;
    }

    //TODO: add id gen
    d.lines = this.sceneLines;
    d.owner = this.owner;
    this.dialogRef.close(d);
  }
}

<h1 mat-dialog-title>Add New Modeler</h1>
<div mat-dialog-content>
  <form class="new-modeler-form" (submit)="onFormSubmit(newModelerForm)" #newModelerForm="ngForm">
    <!-- Modeler Name -->
    <mat-form-field>
      <mat-label>Modeler name</mat-label>
      <input
        placeholder="Modeler name"
        type="text"
        name="name"
        [ngModel]="data.name || ''"
        matInput
        required
      />
    </mat-form-field>

    <!-- Modeler Description -->
    <mat-form-field>
      <mat-label>Modeler description</mat-label>
      <input
        placeholder="Modeler description"
        type="text"
        name="description"
        [ngModel]="data.description || ''"
        matInput
        required
      />
    </mat-form-field>

    <!-- Modeler Main Background -->
    <mat-form-field>
      <mat-label>Main Background URL</mat-label>
      <input
        placeholder="Modeler description"
        type="text"
        name="main_bg_url"
        [ngModel]="data.main_bg_url || ''"
        matInput
        required
      />
    </mat-form-field>

    <!-- Character Selection -->
    <mat-form-field>
      <mat-label>Characters</mat-label>
      <mat-select name="characters" [ngModel]="data.characters" multiple required>
        <mat-option *ngFor="let character of charactersList" [value]="character.name">{{
          character.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Opening Acts Selection -->
    <mat-form-field>
      <mat-label>Opening acts</mat-label>
      <mat-select name="opening_acts" [ngModel]="data.opening_acts" multiple required>
        <mat-option *ngFor="let openingAct of openingActsList" [value]="openingAct">{{
          openingAct
        }}</mat-option>
      </mat-select>
    </mat-form-field>

    <div mat-dialog-actions>
      <button mat-button (click)="onFormSubmit(newModelerForm, true)">No Thanks</button>
      <button mat-button type="submit" cdkFocusInitial>Ok</button>
    </div>
  </form>
</div>

<!-- prettier-ignore -->
<mat-toolbar color="primary" class="fix-nav" *ngIf="selectedUser">
    <ng-template [ngxPermissionsOnly]="permission">
        <button type="button" mat-icon-button class="visible-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
        </button>
        <button type="button" mat-icon-button class="hidden-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>vertical_split</mat-icon>
        </button>
    </ng-template>
    <div class="nav-brand" title="{{subTitle}}">
        <!-- {{title}} -->
        <img src="assets/images/top-logo-min.png" class="circle z-depth-2 responsive-img activator" alt="{{title}}">
        <span style="letter-spacing: 2px;" style="letter-spacing: 4px;" class="hidden-sm">{{title}}</span>
    </div>
    <!-- <form class="hidden-sm" style="margin-left: 20px; margin-top: 5px">
        <mat-form-field>
            <input matInput [placeholder]="'Search' | translate">
        </mat-form-field>
    </form> -->
    <!-- <span class="nav-spacer"></span> -->
    <!-- <a href="https://github.com/start-javascript/sb-admin-material/archive/master.zip" class="hidden-sm" mat-raised-button style="margin-right: 10px">
        <mat-icon>cloud_download</mat-icon> {{ 'Download Now' | translate }}
    </a> -->
    <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="language">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
        <button mat-menu-item (click)="changeLang('en')">
            <span>{{ 'English' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('fr')">
            <span>{{ 'French' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('ur')">
            <span>{{ 'Urdu' | translate }}s</span>
        </button>
    </mat-menu> -->

    <div class="lectureTitle hidden-sm" [class.border-left]="sessionData && roomData" *ngIf="!isMobile">
        <div class="ellipsis production-label"><span *ngIf="showProductionLabel">{{'Production Room' | translate}} -
            </span><span *ngIf="roomData">{{roomData.name}}</span>
        </div>
        <div *ngIf="sessionData" class="ellipsis" title="{{sessionData.name}}">{{sessionData.name}}</div>
    </div>
    <span class="lectureTitle hidden-sm" [hidden]="pageTitle == undefiend">{{pageTitle}}</span>
    <div style="display: inline-flex;">
        <button mat-icon-button style="width: min-content; padding: 5px 15px 0;" [disableRipple]="true" [routerLink]
            (click)="endSession()" *ngIf="sessionData && showEndSession && !sessionData.isKeepActive">
            <span class="lectureendSession">
                {{ 'End Session' | translate }}</span>
        </button>
        <div class="icon-container" *ngIf="!goodConnection && sessionData">
            <button mat-icon-button style="color: orange;cursor: default;">
                <mat-icon>warning</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{ 'Poor Connection' | translate }}
            </div>
        </div>
        <!-- <div class="icon-container" *ngIf="sessionData && highlightNotifications && !lockSite">
            <button mat-icon-button style="color: lime;" (click)="showFrame(pushedTileLink)">
                <mat-icon>notifications_active</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Notification' | translate}}
            </div>
        </div> -->
        <div class="icon-container" *ngIf="donateLink">
            <button mat-icon-button [routerLink] (click)="openDonate()">
                <!-- <mat-icon>group_work
                </mat-icon> -->
                <img src="assets/vertical/{{orgType}}/images/donate.png"
                    class="circle z-depth-2 responsive-img activator" alt="Donate" style="height: 25px;">
            </button>
            <div class="icon-tooltip">
                {{'Donate' | translate}}
            </div>
        </div>
        <!-- <div class="icon-container">
            <button mat-icon-button [routerLink] (click)="openSponsors()">
                <mat-icon>group_work
                </mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Sponsors' | translate}}
            </div>
        </div> -->
        <!-- <div class="icon-container">
            <button mat-icon-button [routerLink] (click)="openAgenda()">
                <mat-icon>menu_book
                </mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Agenda' | translate}}
            </div>
        </div> -->
        <div class="icon-container" *ngIf="hasListingDirectory && !lockSite">
            <button mat-icon-button [routerLink] (click)="gotoBook()" title="{{'Listing and Networking' | translate}}">
                <mat-icon>recent_actors</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'StellarConnect' | translate}}
            </div>
        </div>
        <div class="icon-container"
            *ngIf="hasListingDirectory && !lockSite && (selectedUser['isTextChat'] || selectedUser['isVideoChat'])">
            <button mat-icon-button (click)="toggleDnD()" title="{{'Do Not Disturb' | translate}}">
                <mat-icon [ngStyle]="{'color': selectedUser['chat_dnd'] ? 'red' : ''}">block</mat-icon>
            </button>
            <div class="icon-tooltip" *ngIf="selectedUser['chat_dnd']">
                {{'Chat Off' | translate}}
            </div>
            <div class=" icon-tooltip" *ngIf="!selectedUser['chat_dnd']">
                {{'DND' | translate}}
            </div>
        </div>
        <!-- <div class="icon-container" *ngIf="sessionData && !highlightNotifications">
        <button mat-icon-button (click)="showFrame(pushedTileLink)">
            <mat-icon>notifications_none</mat-icon>
        </button>
        <div class="icon-tooltip">
            {{'No Notifications' | translate}}
        </div>
    </div> -->
        <div class="icon-container">
            <button mat-icon-button [routerLink] (click)="viewProfile()"
                title="{{selectedUser.firstName}} {{selectedUser.lastName}}">
                <!-- <mat-icon>account_circle</mat-icon> -->
                <span style="font-weight: bold">{{selectedUser.firstName | myfirstLetterPipe}}
                    {{selectedUser.lastName | myfirstLetterPipe}}</span>
            </button>
            <div class="icon-tooltip">
                {{'Profile' | translate}}
            </div>
        </div>
        <div class="icon-container" *ngIf="sessionData && !(roomData && roomData.main)" style="padding-right: 15px;">
            <button mat-icon-button [disableRipple]="true" [routerLink]
                title="{{'Go to' | translate}} {{'Home' | translate}}" (click)="goHome()">
                <mat-icon style="color: #f7f7f7; font-size: 30pt;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="profile" >
        <mat-icon>account_circle</mat-icon>
    </button> -->
        <!-- <mat-menu #profile="matMenu">
        <button mat-menu-item [routerLinkActive]="'active'" [routerLink]="['/profile']">
            <mat-icon>person</mat-icon>
            <span>{{ 'Profile' | translate }}</span>
        </button>
        <button mat-menu-item [routerLinkActive]="'active'" [routerLink]="['/settings']">
            <mat-icon>settings</mat-icon>
            <span>{{ 'Settings' | translate }}</span>
        </button>
    </mat-menu> -->
        <!-- *ngIf="!sessionData && !showExit && !(roomData && roomData.main)"     -->
        <div class="icon-container" style="padding-right: 15px;">
            <button mat-icon-button [disableRipple]="true" [routerLink] (click)="goHome()"
                title="{{'Go to' | translate}} {{'Home' | translate}}">
                <mat-icon style="color: #fafafa; font-size: 24pt;">home</mat-icon>
            </button>
            <div style="padding-left: 4px;" class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <!-- showExit || (roomData && roomData.main) -->
        <div class="icon-container">
            <button mat-icon-button (click)="onLoggedout()">
                <mat-icon>exit_to_app</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Log Out' | translate}}
            </div>
        </div>
    </div>
    <div [@slideInOut]="drawerState" class="notification-drawer">
        <div fxFlex fxLayout="row" fxLayoutGap="5px">
            <div fxFlex="90">
                <a (click)="chatWithAttendee()" style="cursor: pointer;">
                </a>
            </div>
            <div fxFlex="10" fxLayoutAlign="end">
                <a (click)="closeDrawer()">
                    <mat-icon>close</mat-icon>
                </a>
            </div>
        </div>
    </div>
    <audio #ringStream src="assets/files/phone_ringing.mpeg" preload="auto" loop="1" autostart="0"></audio>
</mat-toolbar>